import React, { useEffect, useState } from 'react';
import { emailRegistration, fetchClubTrainers } from '../../api/apiHandlers';


// Modal Component
const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: 'white', padding: 20, borderRadius: 5, minWidth: '300px' }}>
                <button onClick={onClose} style={{ float: 'right' }}>Close</button>
                {children}
            </div>
        </div>
    );
};

const AdminManageTrainers = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userType] = useState('trainer');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [trainers, setTrainers] = useState([]);
    const [adminId] = useState(localStorage.getItem('userId'));

    const clubId = localStorage.getItem('clubId');


    // Fetch Trainers
    useEffect(() => {
        const fetchTrainers = async () => {
            try {
                const trainers = await fetchClubTrainers(clubId);
                setTrainers(trainers); // Assuming setTrainers can handle an array of trainers
            } catch (error) {
                console.error('Error fetching trainers:', error);
                // Handle error, e.g., by setting state to show an error message
            }
        };
        fetchTrainers();
        console.log('Trainers:', trainers);
    }, []);



    // Handle Modal Form Submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess(false);

        try {
            await emailRegistration(email, userType, firstName, lastName, clubId);
            setSuccess(true);
            setEmail('');
            setFirstName('');
            setLastName('');
            setIsModalOpen(false); // Close modal on success
        } catch (error) {
            setError(error.message);
        }
    };


    // Testing if clubId is available
    if (!clubId) {
        return <p>Please relog into the application.</p>;
    }

    return (
        <div className="container">
            <h1 className="header">Manage Trainer Accounts</h1>
            <h2>Trainers</h2>
            <div className="admin-card-list">
                {trainers.map(trainer => (
                    <div key={trainer.id} className="admin-card-item">
                        <div className="admin-card-info">
                            <h2>{trainer.firstName} {trainer.lastName}</h2>
                            <p>{trainer.email}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="button-container">
                <button className="button" onClick={() => setIsModalOpen(true)}>Add New Trainer</button>
            </div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {success && <p style={{ color: 'green' }}>Trainer account created successfully. A verification email has been sent.</p>}
                <form className="modal-form" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="firstName">First Name:</label>
                        <input
                            id="firstName"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            id="lastName"
                            type="text"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <button className="button" type="submit">Create Trainer</button>
                </form>
            </Modal>
        </div>
    );
};

export default AdminManageTrainers;
