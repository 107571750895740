import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createWorkoutTemplate, fetchTrainerClients } from '../../api/apiHandlers';

const TemplateCreation = () => {
    const [templateName, setTemplateName] = useState('');
    const [description, setDescription] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [trainerId] = useState(localStorage.getItem('userId'));
    const [clientFacing, setClientFacing] = useState(false);
    const [isUniversal, setIsUniversal] = useState(false);
    const location = useLocation();
    const [programId] = useState(location.state?.programId);
    const [scheduleId] = useState(location.state?.scheduleId);
    const [selectedClientId, setSelectedClientId] = useState('');
    const [clients, setClients] = useState([]);

    // Fetch Trainer Clients
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await fetchTrainerClients(trainerId);
                console.log('Fetched clients: ', response);
                if (response && response.length > 0) {
                    setClients(response);
                } else {
                    throw new Error('Failed to retrieve clients');
                }
            } catch (err) {
                setError('Failed to retrieve clients. Please try again.');
                console.error(err);
            }
        };
        fetchClients();
    }, [trainerId]);

    // Handler for submitting the form to create a new workout template
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const postData = {
                name: templateName,
                description: description,
                trainerId: localStorage.getItem('userId'),
                clientFacing: clientFacing ? 1 : 0, // Convert to tinyint
                isUniversal: isUniversal ? 1 : 0, // Convert to tinyint
                programReserved: programId || null, // Set to null if not provided
                clientId: selectedClientId // Changed to clientId
            };
            const response = await createWorkoutTemplate(postData);
            if (response && response.TemplateID) {
                navigate('/select-exercises', { state: { templateId: response.TemplateID, programId: programId, scheduleId: scheduleId } });
            } else {
                throw new Error('Failed to retrieve the TemplateID');
            }
            console.log('Data being sent to server: ', postData);
        } catch (err) {
            setError('Failed to create workout template. Please try again.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };
    
    

    const handleClientSelection = (event) => {
        setSelectedClientId(event.target.value);
    };

    return (
        <div>
            <h1>Create New Workout Template</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="templateName">Template Name:</label>
                <input
                    id="templateName"
                    type="text"
                    value={templateName}
                    onChange={e => setTemplateName(e.target.value)}
                    required
                />
                <label htmlFor="description">Description:</label>
                <textarea
                    id="description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    required
                />
                <label htmlFor="clientFacing">Client Facing:</label>
                <input
                    id="clientFacing"
                    type="checkbox"
                    checked={clientFacing}
                    onChange={e => setClientFacing(e.target.checked)}
                />
                <label htmlFor="isUniversal">Universal Template:</label>
                <input
                    id="isUniversal"
                    type="checkbox"
                    checked={isUniversal}
                    onChange={e => setIsUniversal(e.target.checked)}
                />
                <label htmlFor="clientSelect">Assign to Client:</label>
                <select onChange={handleClientSelection} value={selectedClientId}>
                    <option value="">No client selected</option>
                    {clients.map(client => (
                        <option key={client.UserID} value={client.UserID}>
                            {client.FirstName} {client.LastName}
                        </option>
                    ))}
                </select>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Creating...' : 'Create Template'}
                </button>
            </form>
            {error && <p>{error}</p>}
        </div>
    );
}

export default TemplateCreation;
