import React, { useState, useEffect } from 'react';
import ExtractedMuscleMap from './ExtractedMuscleMap';

const muscleGroupMap = {
    bicep: 'front',
    tricep: 'back',
    shoulder: 'front',
    chest: 'front',
    core: 'front',
    quad: 'front',
    hamstring: 'back',
    calf: 'back',
    glute: 'back',
    lat: 'back',
    upperback: 'back',
    trap: 'front' // or 'back' based on where you want to highlight
};

const MuscleMapping = () => {
    const [selectedMuscles, setSelectedMuscles] = useState([]);

    const handleMuscleChange = (event) => {
        const options = event.target.options;
        const selectedOptions = [];
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) {
                selectedOptions.push(options[i].value);
            }
        }
        setSelectedMuscles(selectedOptions);
    };

    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        padding: '20px',
        boxSizing: 'border-box',
        height: '100vh',
        overflow: 'hidden'
    };

    const muscleMapContainerStyle = {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const svgStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%'
    };

    return (
        <div style={containerStyle}>
            <h1>Muscle Mapping</h1>
            <div>
                <label htmlFor="muscle-group-select">Select Muscle Group(s): </label>
                <select
                    id="muscle-group-select"
                    multiple
                    value={selectedMuscles}
                    onChange={handleMuscleChange}
                >
                    <option value="bicep">Biceps</option>
                    <option value="tricep">Triceps</option>
                    <option value="shoulder">Shoulders</option>
                    <option value="chest">Chest</option>
                    <option value="core">Core</option>
                    <option value="quad">Quadriceps</option>
                    <option value="hamstring">Hamstrings</option>
                    <option value="calf">Calves</option>
                    <option value="glute">Glutes</option>
                    <option value="lat">Lats</option>
                    <option value="upperback">Upper Back</option>
                    <option value="trap">Traps</option>
                </select>
            </div>
            <div style={muscleMapContainerStyle}>
                <div style={svgStyle}>
                    <ExtractedMuscleMap selectedMuscles={selectedMuscles} />
                </div>
            </div>
        </div>
    );
};

export default MuscleMapping;
