import React, { useState, useEffect } from 'react';
import {
    fetchMuscleGroups,
    fetchExerciseEquipment,
    createNewExerciseWithImage
} from '../../api/apiHandlers';

const CreateExerciseModal = ({ isCreateExerciseModalOpen, toggleCreateExerciseModal, onModalClose }) => {
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newExerciseData, setNewExerciseData] = useState({
        name: '',
        description: '',
        trackTime: false,
        trackDistance: false,
        trackWeight: false,
        trackReps: false,
        isUnilateral: false,
        isBilateral: false,
        primaryMuscleGroup: '',
        assistingMuscleGroup: '',
        isCardio: false,
        equipment: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [message, setMessage] = useState('');

    useEffect(() => {
        if (!isCreateExerciseModalOpen) return;

        const getMuscleGroups = async () => {
            setIsLoading(true);
            try {
                const muscleGroups = await fetchMuscleGroups();
                setMuscleGroups(muscleGroups);
            } catch (error) {
                console.error('Failed to fetch muscle groups:', error);
            }
            setIsLoading(false);
        };

        getMuscleGroups();
    }, [isCreateExerciseModalOpen]);

    useEffect(() => {
        if (!isCreateExerciseModalOpen) return;

        const getEquipment = async () => {
            setIsLoading(true);
            try {
                const equipment = await fetchExerciseEquipment();
                setEquipment(equipment);
            } catch (error) {
                console.error('Failed to fetch equipment:', error);
            }
            setIsLoading(false);
        };

        getEquipment();
    }, [isCreateExerciseModalOpen]);

    const retrieveTrainerId = () => {
        const trainerUserId = parseInt(localStorage.getItem('userId'), 10);
        return trainerUserId;
    }

    useEffect(() => {
        const trainerId = retrieveTrainerId();
    }, []);

    if (!isCreateExerciseModalOpen) {
        return null;
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fileType = file?.type; // Use optional chaining to handle the case when no file is selected

        // Check if the file type is jpg or jpeg
        if (fileType === "image/jpeg" || fileType === "image/jpg") {
            setSelectedFile(file);
        } else {
            alert("Please select a .jpg or .jpeg file.");
            event.target.value = null; // Clear the file input
        }
    };

    const handleCreateNewExercise = async (event) => {
        event.preventDefault();

        console.log('Create new exercise button clicked.')

        try {
            const trainerId = localStorage.getItem('userId');

            const exerciseDataToSend = {
                name: newExerciseData.name,
                description: newExerciseData.description,
                tracksTime: newExerciseData.trackTime,
                tracksDistance: newExerciseData.trackDistance,
                tracksWeight: newExerciseData.trackWeight,
                tracksReps: newExerciseData.trackReps,
                isUnilateral: newExerciseData.isUnilateral,
                isBilateral: newExerciseData.isBilateral,
                primaryMuscleGroupID: newExerciseData.primaryMuscleGroupID,
                assistingMuscleGroupID: newExerciseData.assistingMuscleGroupID,
                isCardio: newExerciseData.isCardio,
                equipmentID: newExerciseData.equipmentID,
                trainerId: trainerId
            };

            console.log('Exercise data to send:', exerciseDataToSend);

            await createNewExerciseWithImage(exerciseDataToSend, selectedFile);

            // Close modal and reload exercises
            toggleCreateExerciseModal();
            onModalClose(); // Call the passed function to reload exercises
        } catch (error) {
            console.error('Error creating new exercise:', error);
            setMessage('Error creating new exercise: ' + error.message);
        }
    };

    const closeModal = () => {
        toggleCreateExerciseModal();
        onModalClose();
    };

    const handleCheckboxChange = (property, value) => {
        setNewExerciseData(prevData => ({
            ...prevData,
            [property]: value
        }));
    };

    return (
        <div className="modal">
            <div className="modal-content">
                <button className="modal-close-btn" onClick={closeModal}>×</button>
                <form className="modal-form" onSubmit={handleCreateNewExercise}>
                    <input
                        type="text"
                        placeholder="Exercise Name"
                        onChange={(e) => setNewExerciseData({ ...newExerciseData, name: e.target.value })}
                    />
                    <input
                        type="text"
                        placeholder="Notes"
                        onChange={(e) => setNewExerciseData({ ...newExerciseData, description: e.target.value })}
                    />
                    <label htmlFor="fileInput">Upload Exercise Image:</label>
                    <input
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                    />
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackTime', e.target.checked)}
                            /> Track Time
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackDistance', e.target.checked)}
                            /> Track Distance
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackWeight', e.target.checked)}
                            /> Track Weight
                        </label>
                    </div>
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                onChange={(e) => handleCheckboxChange('trackReps', e.target.checked)}
                            /> Track Reps
                        </label>
                    </div>
                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                equipmentID: e.target.value
                            });
                        }}
                    >
                        <option value="">Exercise Equipment...</option>
                        {equipment.map((equipmentItem) => (
                            <option key={equipmentItem.EquipmentID} value={equipmentItem.EquipmentID}>
                                {equipmentItem.EquipmentName}
                            </option>
                        ))}
                    </select>
                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                isUnilateral: e.target.value === 'unilateral',
                                isBilateral: e.target.value === 'bilateral',
                                isCardio: e.target.value === 'cardio'
                            });
                        }}
                    >
                        <option value="">Exercise Focus...</option>
                        <option value="unilateral">Unilateral</option>
                        <option value="bilateral">Bilateral</option>
                        <option value="cardio">Cardio</option>
                    </select>
                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                primaryMuscleGroupID: e.target.value
                            });
                        }}
                    >
                        <option value="">Primary Muscle Group...</option>
                        {muscleGroups.map((muscleGroup) => (
                            <option key={muscleGroup.MuscleGroupID} value={muscleGroup.MuscleGroupID}>
                                {muscleGroup.MuscleGroupName}
                            </option>
                        ))}
                    </select>
                    <select
                        onChange={(e) => {
                            setNewExerciseData({
                                ...newExerciseData,
                                assistingMuscleGroupID: e.target.value
                            });
                        }}
                    >
                        <option value="">Assisting Muscle Group...</option>
                        {muscleGroups.map((muscleGroup) => (
                            <option key={muscleGroup.MuscleGroupID} value={muscleGroup.MuscleGroupID}>
                                {muscleGroup.MuscleGroupName}
                            </option>
                        ))}
                    </select>
                    <button type="button" className="button" onClick={handleCreateNewExercise}>Create Exercise</button>
                    <button type="button" className="button" onClick={toggleCreateExerciseModal}>Cancel</button>
                    <p>{message}</p>
                </form>
            </div>
        </div>
    );
};

export default CreateExerciseModal;
