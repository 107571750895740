import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    fetchWorkoutSessions,
    startNewWorkoutSession,
    fetchUserProfile,
    fetchAllWorkoutSessions,
    fetchWorkoutTemplatesForClient,
    fetchProgramScheduleForClient,
    fetchProgramDetails,
    fetchSpecificWorkoutTemplate,
    activateProgramSession,
    rescheduleProgramSession,
    fetchSessionExercises
} from '../../api/apiHandlers';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useFetchClientPrograms } from '../custom-hooks/FetchClientPrograms';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import ExtractedMuscleMap from '../muscle-mapping/ExtractedMuscleMap';

const ClientWorkoutSessions = () => {
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [sessions, setSessions] = useState([]);
    const [finishedProgramSessions, setFinishedProgramSessions] = useState([]);
    const [sessionsInProgress, setSessionsInProgress] = useState([]);
    const [programSessions, setProgramSessions] = useState([]);
    const [clientName, setClientName] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [workoutTemplates, setWorkoutTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [workoutProgram, setWorkoutProgram] = useState(null);
    const [programId, setProgramId] = useState(null);
    const [error, setError] = useState(null);
    const [programSchedule, setProgramSchedule] = useState(new Set());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const [sessionExercises, setSessionExercises] = useState([]);
    const [primaryMuscleGroups, setPrimaryMuscleGroups] = useState([]);
    const [assistingMuscleGroups, setAssistingMuscleGroups] = useState([]);


    const workoutDates = useMemo(() => {
        const dates = new Set(sessions.map(session => session.Date.split('T')[0]));
        return dates;
    }, [sessions]);

    const finishedProgramSessionDates = useMemo(() => {
        const dates = new Set(finishedProgramSessions.map(session => session.Date.split('T')[0]));
        return dates;
    }, [finishedProgramSessions]);

    const programDates = useMemo(() => {
        const dates = new Set(programSessions.map(session => session.ScheduledDate.split('T')[0]));
        return dates;
    }, [programSessions]);

    const sessionsInProgressDates = useMemo(() => {
        const dates = new Set(sessionsInProgress.map(session => session.Date.split('T')[0]));
        return dates;
    }, [sessionsInProgress]);


    const allSessions = [...sessionsInProgress, ...sessions, ...programSessions, ...finishedProgramSessions];
    console.log('All Sessions:', allSessions);



    // Fetch Client Name
    useEffect(() => {
        const fetchClientName = async () => {
            let clientData;
            try {
                clientData = await fetchUserProfile(clientId);
                setClientName(`${clientData.FirstName} ${clientData.LastName}`);
            } catch (error) {
                console.error('Error fetching client name:', error);
            }
        }
        fetchClientName();
    }, [clientId]);


    // Fetch Workout Sessions for Client
    useEffect(() => {
        const loadSessions = async () => {
            try {
                const response = await fetchWorkoutSessions(clientId);

                const sessionsWithIsFinished = response.map(session => ({
                    ...session,
                    isFinished: Boolean(session.IsFinished)
                }));

                const sessionsInProgress = sessionsWithIsFinished.filter(session => !session.isFinished).map(session => ({
                    ...session,
                    SessionType: 'InProgressSessions'
                }));
                setSessionsInProgress(sessionsInProgress);
                console.log('Sessions In Progress:', sessionsInProgress);

                const finishedSessions = sessionsWithIsFinished.filter(session => session.isFinished);

                const programSessions = finishedSessions.filter(session => session.isProgramSession);
                const nonProgramSessions = finishedSessions.filter(session => !session.isProgramSession);

                setFinishedProgramSessions(programSessions.map(session => ({
                    ...session,
                    SessionType: 'FinishedProgramSessions'
                })));

                setSessions(nonProgramSessions.map(session => ({
                    ...session,
                    SessionType: 'FinishedSessions'
                })));

                console.log('Finished Program Sessions:', programSessions);
                console.log('Finished Non-Program Sessions:', nonProgramSessions);
            } catch (error) {
                console.error('Error fetching workout sessions:', error);
            }
        };
        loadSessions();
    }, [clientId]);

    // Fetch Program Schedules for Client
    useEffect(() => {
        const fetchProgramSchedules = async () => {
            try {
                const programs = await fetchProgramScheduleForClient(clientId);

                if (programs.length > 0) {
                    setProgramId(programs[0].ProgramID);
                    const sessionsInProgram = await fetchProgramDetails(programs[0].ProgramID);

                    // Fetch the correlated template for each object
                    const templates = await Promise.all(sessionsInProgram.map(session => fetchSpecificWorkoutTemplate(session.TemplateID)));

                    // Merge the properties from both the sessionsInProgram objects and the corresponding templates objects
                    let mergedObjects = sessionsInProgram.map((session, index) => ({ ...session, ...templates[index], SessionType: 'ProgramSession' }));

                    // Get the ids of finished program sessions
                    const finishedProgramSessionIds = finishedProgramSessions.map(session => session.ProgramDetailsID);

                    // Filter out the sessions that exist in finished program sessions
                    mergedObjects = mergedObjects.filter(session => !finishedProgramSessionIds.includes(session.ProgramDetailsID));

                    setProgramSessions(mergedObjects);
                }
            } catch (error) {
                console.error('Error fetching program schedules or details:', error);
            }
        };

        if (clientId) {
            fetchProgramSchedules();
        }
    }, [clientId, finishedProgramSessions, refreshKey]);


    // Fetch Program Workout Templates for Client
    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                let templates = await fetchWorkoutTemplatesForClient(clientId);
                setWorkoutTemplates(templates);
                console.log('======Workout Templates:', templates);
            } catch (err) {
                setError('Failed to fetch workout templates');
                console.error(err);
            };
        };
        fetchTemplates();
    }, []);

    // Fetch Session Exercises
    useEffect(() => {
        const fetchExercisesForDate = async () => {
            // Find the session for the selected date
            const session = allSessions.find(session => {
                const sessionDate = new Date(session.Date || session.ScheduledDate).toLocaleDateString();
                return sessionDate === selectedDate.toLocaleDateString();
            });

            if (session) {
                try {
                    const exercises = await fetchSessionExercises(session.SessionID);
                    setSessionExercises(exercises);
                    console.log('Session Exercises:', exercises)

                    // Extract and store primary and assisting muscle groups
                    const primaryMuscleGroups = exercises.map(exercise => exercise.PrimaryMuscleGroup).filter(Boolean);
                    const assistingMuscleGroups = exercises.map(exercise => exercise.AssistingMuscleGroup).filter(Boolean);

                    setPrimaryMuscleGroups(primaryMuscleGroups);
                    setAssistingMuscleGroups(assistingMuscleGroups);

                    console.log('Muscle Groups Found:', primaryMuscleGroups, assistingMuscleGroups)

                } catch (error) {
                    console.error('Error fetching session exercises:', error);
                }
            } else {
                setSessionExercises([]); // Clear the exercises if no session is found
                console.log('No session found for the selected date')
            }
        };

        fetchExercisesForDate();
    }, [selectedDate]);







    // Handle New Session Click
    const handleSessionClick = (session, navigate, clientId) => {
        if (session.isFinished) {
            const isProgramSession = session.SessionType === 'FinishedProgramSessions';
            console.log('Is Program Session:', isProgramSession);
            navigate(`/workout-session/${session.SessionID}/${clientId}`, { state: { isProgramSession } });
        } else {
            navigate(`/track-workout-session/${session.SessionID}/${clientId}`);
        }
    };

    // Handle Start New Workout
    const handleStartNewWorkout = async () => {
        try {
            const response = await startNewWorkoutSession(clientId, selectedDate);
            navigate(`/track-workout-session/${response.SessionID}/${clientId}`, { state: { selectedTemplate } });
        } catch (error) {
            console.error('Error starting new workout session:', error);
        }
    };

    const handleStartWorkoutWithProgram = async (session) => {
        if (!session || !session.TemplateID) return;

        try {
            const response = await startNewWorkoutSession(clientId, selectedDate, session.TemplateID);
            await activateProgramSession(session.ProgramDetailsID);
            navigate(`/track-workout-session/${response.SessionID}/${clientId}`, { state: { selectedTemplate: session.TemplateID } });
        } catch (error) {
            console.error('Error starting new workout session with program template:', error);
        }
    };

    const handleRescheduleSession = (session) => {
        setIsOpen(true);
    };

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }


    // Navigate to Client Profile
    const navigateToClientProfile = (navigate, clientId) => {
        navigate(`/client-profile/${clientId}`);
    };

    // On Date Change
    const onDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleTemplateSelect = (event) => {
        setSelectedTemplate(event.target.value);
    };


    return (
        <div>
            <h2 className="header">{clientName}</h2>
            {
                localStorage.getItem('userType') !== 'client' &&
                <button className="button" onClick={() => navigateToClientProfile(navigate, clientId)}>View Profile</button>
            }
            <Calendar
                onChange={onDateChange}
                value={selectedDate}
                calendarType="US"
                tileContent={({ date, view }) => {
                    const dateString = date.toISOString().split('T')[0];
                    if (sessionsInProgressDates.has(dateString)) {
                        return <div className="workout-marker red-dot">•</div>;
                    } else if (finishedProgramSessionDates.has(dateString)) {
                        return <div className="workout-marker gold-dot">•</div>;
                    } else if (programDates.has(dateString)) {
                        return <div className="workout-marker purple-dot">•</div>;
                    } else if (workoutDates.has(dateString)) {
                        return <div className="workout-marker green-dot">•</div>;
                    }
                    return null;
                }}
            />

            <div className="selected-date-workouts">
                {
                    allSessions
                        .filter(session => {
                            const isSameDate = new Date(session.Date || session.ScheduledDate).toLocaleDateString() === selectedDate.toLocaleDateString();
                            const isNotStartedProgramSession = !(session.SessionType === 'ProgramSession' && session.DidStart);
                            if (isSameDate && isNotStartedProgramSession) {
                                console.log('Filtered session:', session);
                                return true;
                            }
                            return false;
                        })
                        .map((session, index) => (
                            <div key={index}>
                                <div className="session-item"
                                    onClick={() => session.SessionType === 'ProgramSession' ? handleStartWorkoutWithProgram(session) : handleSessionClick(session, navigate, clientId)}
                                >
                                    <h4>Description: {session.Description ? session.Description : "No description provided"}</h4>
                                    <p>Notes: {session.SessionNotes ? session.SessionNotes : "No notes provided"}</p>
                                    <h4>Primary Muscles Targeted:</h4>
                                    <div style={{ width: '15%', height: '15%' }}>
                                        <ExtractedMuscleMap selectedMuscles={[...primaryMuscleGroups]} />
                                    </div>
                                </div>
                                {session.SessionType === 'ProgramSession' &&
                                    <div>
                                        <button className="button-reschedule-workout" onClick={openModal}>
                                            Reschedule Session
                                        </button>
                                        <Modal className="modal"
                                            isOpen={modalIsOpen}
                                            onRequestClose={closeModal}
                                            contentLabel="Reschedule Session"
                                        >
                                            <div className="modal-content" style={{
                                                position: 'relative',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <h2>Reschedule Session</h2>
                                                <button className="modal-close-btn" onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px' }}>×</button>
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={async (date) => {
                                                        setIsOpen(false);
                                                        setSelectedDate(date);
                                                        const scheduledDate = session.ScheduledDate;
                                                        const newScheduledDate = date.toISOString().split('T')[0];
                                                        try {
                                                            const response = await rescheduleProgramSession(scheduledDate, newScheduledDate);
                                                            console.log(response);
                                                            // Increment refreshKey to trigger a re-render
                                                            setRefreshKey(oldKey => oldKey + 1);
                                                        } catch (error) {
                                                            console.error('Failed to reschedule session:', error);
                                                            // Handle the error here, e.g., show an error message to the user
                                                        }
                                                    }}
                                                    openToDate={new Date()} // Open to today's date
                                                    inline // Show the calendar inline
                                                />
                                            </div>
                                        </Modal>
                                    </div>
                                }
                            </div>
                        ))
                }
                {
                    sessions.filter(session => new Date(session.Date).toDateString() === selectedDate.toDateString()).length === 0 &&
                    !allSessions.some(session => {
                        const isSameDate = new Date(session.Date || session.ScheduledDate).toLocaleDateString() === selectedDate.toLocaleDateString();
                        return isSameDate && (session.SessionType === 'InProgressSessions' || session.SessionType === 'ProgramSession');
                    }) &&
                    <div>
                        <button className="button-new-workout" onClick={handleStartNewWorkout}>
                            Start New Workout
                        </button>
                        <h3>Select Workout Template</h3>
                        <select onChange={handleTemplateSelect}>
                            <option value="">No template selected.</option>
                            {workoutTemplates.map((template, index) => (
                                <option key={index} value={template.TemplateID}>
                                    {template.Name}
                                </option>
                            ))}
                        </select>
                    </div>
                }
            </div>
        </div>
    );


};

export default ClientWorkoutSessions;