import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { FrontMuscleMapSVG, BackMuscleMapSVG } from './RawMuscleMapSVG';

const muscleGroupMap = {
    bicep: 'front',
    tricep: 'back',
    shoulder: 'front',
    chest: 'front',
    core: 'front',
    quad: 'front',
    hamstring: 'back',
    calf: 'back',
    glute: 'back',
    lat: 'back',
    upperback: 'back',
    trap: 'front' // or 'back' based on where you want to highlight
};

const muscleGroupNameMapping = {
    "Chest": 'chest',
    "Upper Back": 'upperback',
    "Lats": 'lat',
    "Shoulders": 'shoulder',
    "Biceps": 'bicep',
    "Triceps": 'tricep',
    "Quadriceps": 'quad',
    "Glutes": 'glute',
    "Hamstrings": 'hamstring',
    "Core": 'core',
    "Calves": 'calf'
};

const ExtractedMuscleMap = ({ selectedMuscles }) => {
    const rawFrontSVGString = ReactDOMServer.renderToString(<FrontMuscleMapSVG />);
    const rawBackSVGString = ReactDOMServer.renderToString(<BackMuscleMapSVG />);

    const mapMuscleGroupNames = (muscleGroups) => {
        return muscleGroups.map(muscleGroup => {
            if (!muscleGroup) return '';
            const mappedMuscle = muscleGroupNameMapping[muscleGroup];
            return mappedMuscle ? mappedMuscle : muscleGroup.toLowerCase();
        }).filter(muscleGroup => muscleGroup); // Remove empty strings
    };

    const extractPaths = (svgMarkup, muscleGroups) => {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgMarkup, 'image/svg+xml');

        muscleGroups.forEach(muscleGroup => {
            const paths = svgDoc.querySelectorAll(`[id*="${muscleGroup}"]`);
            paths.forEach(path => {
                path.style.fill = 'red';
            });
        });

        return svgDoc.documentElement.outerHTML;
    };

    const mappedMuscles = mapMuscleGroupNames(selectedMuscles);

    const frontMuscles = mappedMuscles.filter(muscle => muscleGroupMap[muscle] === 'front');
    const backMuscles = mappedMuscles.filter(muscle => muscleGroupMap[muscle] === 'back');

    const highlightedFrontSVG = extractPaths(rawFrontSVGString, frontMuscles);
    const highlightedBackSVG = extractPaths(rawBackSVGString, backMuscles);

    const renderBoth = frontMuscles.length > 0 && backMuscles.length > 0;
    const renderFrontOnly = frontMuscles.length > 0 && backMuscles.length === 0;
    const renderBackOnly = backMuscles.length > 0 && frontMuscles.length === 0;

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {renderFrontOnly && (
                <div dangerouslySetInnerHTML={{ __html: highlightedFrontSVG }} style={{ width: '100%', height: 'auto' }} />
            )}
            {renderBackOnly && (
                <div dangerouslySetInnerHTML={{ __html: highlightedBackSVG }} style={{ width: '100%', height: 'auto' }} />
            )}
            {renderBoth && (
                <>
                    <div dangerouslySetInnerHTML={{ __html: highlightedFrontSVG }} style={{ width: '50%', height: 'auto' }} />
                    <div dangerouslySetInnerHTML={{ __html: highlightedBackSVG }} style={{ width: '50%', height: 'auto' }} />
                </>
            )}
        </div>
    );
};

export default ExtractedMuscleMap;
