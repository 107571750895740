import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { loginUserWithToken } from '../../api/apiHandlers';

const TokenLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        const handleTokenLogin = async (token) => {
            try {
                const response = await loginUserWithToken(token);
                if (response.success) {
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('userId', response.userId);
                    localStorage.setItem('userType', response.userType);

                    if (response.userType === 'admin' || response.userType === 'testAdmin') {
                        navigate('/admin-components/AdminHome');
                    } else if (response.userType === 'client' || response.userType === 'testClient') {
                        navigate(`/client-workout-sessions/${response.userId}`);
                    } else if (response.userType === 'masterUser') {
                        navigate('/master-user-homepage');
                    } else {
                        navigate('/clients');
                    }
                } else {
                    console.error('Automatic login failed.');
                }
            } catch (error) {
                console.error('Error during token login:', error);
            }
        };

        if (token) {
            handleTokenLogin(token);
        }
    }, [location, navigate]);

    return null; // No need to render anything
};

export default TokenLogin;
