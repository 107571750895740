import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAllClubInformation } from '../../api/apiHandlers';

const MasterUserHomepage = () => {
    const [clubData, setClubData] = useState([]);
    const [timeFrame, setTimeFrame] = useState('week');
    const navigate = useNavigate();

    /* ============================== Fetch Club Information ============================== */

    // Fetch all club information
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAllClubInformation();
                setClubData(data.Clubs);
            } catch (error) {
                console.error('Error fetching club information:', error);
            }
        };

        fetchData();
    }, []);

    const navigateToClubDetail = (clubId) => {
        navigate(`/master-club-performance/${clubId}`);
    };

    console.log('Club data:', clubData);

    /* ============================== Render ============================== */
    return (
        <div className="container">
            <h1 className="header">Tracker Portal Usage</h1>
            <div className="admin-card-list">
                {clubData.map(club => (
                    <div
                        key={club.clubId}
                        onClick={() => navigateToClubDetail(club.clubId)}
                        className="admin-card-item"
                    >
                        <h2>{club.clubName}</h2>
                        <div className="admin-card-info">
                            <p><strong>Admins:</strong> {club.admins.length}</p>
                            {/* Placeholder for additional admin summary data */}
                        </div>
                        <div className="admin-card-info">
                            <p><strong>Trainers:</strong> {club.trainers.length}</p>
                            {/* Placeholder for additional trainer summary data */}
                        </div>
                        <div className="admin-card-info">
                            <p><strong>Clients:</strong> {club.clients.length}</p>
                            {/* Placeholder for additional client summary data */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MasterUserHomepage;
