import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchTrainerAppointments, fetchTrainerClients, fetchAppointmentCategories } from '../api/apiHandlers';
import moment from 'moment';

const TrainerDashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTime, setCurrentTime] = useState(moment().format('MMMM Do YYYY, h:mm:ss a'));

  // Fetch appointments when the component mounts
  useEffect(() => {
    const getAppointments = async () => {
      const trainerId = localStorage.getItem('userId');
      const data = await fetchTrainerAppointments(trainerId);
      const sortedAppointments = data
        .filter(app => moment(app.start_time).isAfter(moment()))
        .sort((a, b) => new Date(a.start_time) - new Date(b.start_time));
      setAppointments(sortedAppointments);
      setIsLoading(false);
    };
    getAppointments();
  }, []);

  // Fetch clients when the component mounts
  useEffect(() => {
    const getClients = async () => {
      const trainerId = localStorage.getItem('userId');
      const data = await fetchTrainerClients(trainerId);
      setClients(data);
    };
    getClients();
  }, []);

  // Fetch categories when the component mounts
  useEffect(() => {
    const getCategories = async () => {
      const data = await fetchAppointmentCategories();
      setCategories(data);
    };
    getCategories();
  }, []);

  // Update the current time every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format('MMMM Do YYYY, h:mm:ss a'));
    }, 1000);

    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  // Function to get the client's name by ID
  const getClientName = (clientId) => {
    const client = clients.find(client => client.UserID === clientId);
    return client ? `${client.FirstName} ${client.LastName}` : `Client ID ${clientId}`;
  };

  // Function to get the category name by ID
  const getCategoryName = (categoryId) => {
    const category = categories.find(category => category.category_id === categoryId);
    return category ? category.name : `Category ID ${categoryId}`;
  };

  // Filter today's appointments
  const todayAppointments = appointments.filter(app =>
    moment(app.start_time).isSame(moment(), 'day')
  );

  // Filter upcoming appointments within the next 3 days
  const upcomingAppointments = appointments.filter(app =>
    moment(app.start_time).isAfter(moment(), 'day') &&
    moment(app.start_time).isBefore(moment().add(4, 'days'), 'day')
  );

  return (
    <div className="trainer-dashboard">
      <h1>Trainer Dashboard</h1>
      <div className="current-time">
        <h2>{moment().format('dddd, MMMM Do YYYY')}</h2>
        <h3>{currentTime}</h3>
      </div>
      <div className="today-appointments">
        <h2>Today's Appointments</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {todayAppointments.length > 0 ? (
              todayAppointments.map(app => (
                <li key={app.appointment_id}>
                  <strong>{moment(app.start_time).format('h:mm a')}</strong> - {getCategoryName(app.category_id)} with {getClientName(app.client_id)}
                </li>
              ))
            ) : (
              <p>No appointments today</p>
            )}
          </ul>
        )}
      </div>
      <div className="upcoming-appointments">
        <h2>Upcoming Appointments</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <ul>
            {upcomingAppointments.length > 0 ? (
              upcomingAppointments.map(app => (
                <li key={app.appointment_id}>
                  <strong>{moment(app.start_time).format('dddd, MMMM Do YYYY, h:mm a')}</strong> - {getCategoryName(app.category_id)} with {getClientName(app.client_id)}
                </li>
              ))
            ) : (
              <p>No upcoming appointments</p>
            )}
          </ul>
        )}
      </div>
      <div className="quick-access-buttons">
        <Link to="/create-appointment" className="button">Create Appointment</Link>
        <Link to="/view-appointments" className="button">View Schedule</Link>
      </div>
    </div>
  );
};

export default TrainerDashboard;
