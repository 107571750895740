// CreateClub.js

import { useState } from 'react';
import { createClub } from '../../api/apiHandlers';




const CreateClub = () => {
    const [clubName, setClubName] = useState('');
    const [users, setUsers] = useState([{ email: '', userType: '', firstName: '', lastName: '' }]);




    const handleSubmit = async (event) => {
        event.preventDefault();
    
        try {
            const response = await createClub({ clubName, users });
            console.log(response.data);
        } catch (error) {
            console.error('Error creating club:', error);
        }
    };

    const handleUserChange = (index, event) => {
        const values = [...users];
        values[index][event.target.name] = event.target.value;
        setUsers(values);
    };

    const addUser = () => {
        setUsers([...users, { email: '', userType: '', firstName: '', lastName: '' }]);
    };

    const removeUser = (index) => {
        const values = [...users];
        values.splice(index, 1);
        setUsers(values);
    };




    return (
        <div className="container">
            <h1>Create a New Club</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="clubName">Club Name</label>
                <input type="text" id="clubName" value={clubName} onChange={(e) => setClubName(e.target.value)} />

                {users.map((user, index) => (
                    <div key={index}>
                        <label htmlFor={`email${index}`}>Email</label>
                        <input type="email" id={`email${index}`} name="email" value={user.email} onChange={(e) => handleUserChange(index, e)} />

                        <label htmlFor={`userType${index}`}>User Type</label>
                        <select id={`userType${index}`} name="userType" value={user.userType} onChange={(e) => handleUserChange(index, e)}>
                            <option value="">Select...</option>
                            <option value="admin">Admin</option>
                            <option value="trainer">Trainer</option>
                        </select>

                        <label htmlFor={`firstName${index}`}>First Name</label>
                        <input type="text" id={`firstName${index}`} name="firstName" value={user.firstName} onChange={(e) => handleUserChange(index, e)} />

                        <label htmlFor={`lastName${index}`}>Last Name</label>
                        <input type="text" id={`lastName${index}`} name="lastName" value={user.lastName} onChange={(e) => handleUserChange(index, e)} />

                        <button type="button" onClick={() => removeUser(index)}>Remove User</button>
                    </div>
                ))}

                <button type="button" onClick={addUser}>Add User</button>
                <div>
                    <button type="submit">Create Club</button>
                </div>
            </form>
        </div>
    );
};

export default CreateClub;