import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
    fetchClientsProfiles, 
    fetchSpecificWorkoutProgram, 
    addProgramSchedule,
    fetchProgramScheduleProgramId,
    updateProgramSchedule,
    deleteProgramDetails
} from '../../api/apiHandlers';

const ProgramSchedule = () => {
    const location = useLocation();
    const [program, setProgram] = useState(null);
    const [programId, setProgramId] = useState(location.state?.programId);
    const [programScheduleDetails, setProgramScheduleDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [workoutsPerWeek, setWorkoutsPerWeek] = useState(1);
    const [status, setStatus] = useState('active');
    const navigate = useNavigate();

    // Fetch Program
    useEffect(() => {
        const fetchProgram = async () => {
            try {
                const fetchedProgram = await fetchSpecificWorkoutProgram(programId);
                setProgram(fetchedProgram);
                console.log('Program:', fetchedProgram);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch program:', err);
            }
        };

        if (programId) {
            fetchProgram();
        }
    }, [programId]);

    // Fetch Clients
    useEffect(() => {
        const fetchClients = async () => {
            const trainerId = localStorage.getItem('userId');
            try {
                const clients = await fetchClientsProfiles(trainerId);
                setClients(clients);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch clients:', err);
            }
        };

        fetchClients();
    }, []);

    // Fetch Program Schedule Details
    useEffect(() => {
        const fetchProgramScheduleDetails = async () => {
            try {
                const fetchedProgramScheduleDetails = await fetchProgramScheduleProgramId(programId);
                setProgramScheduleDetails(fetchedProgramScheduleDetails);
                console.log('Program Schedule Details:', fetchedProgramScheduleDetails);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch program schedule details:', err);
            }
        };

        if (programId) {
            fetchProgramScheduleDetails();
        }
    }, [programId]);

    const handleScheduleSubmit = async () => {
        try {
            let response;
            if (programScheduleDetails.length > 0) {
                // Update Program Schedule
                const scheduleId = programScheduleDetails[0].ScheduleID;
                // Convert selectedClient to Number
                const selectedClientNumber = Number(selectedClient);
                console.log('Updating for Client:', selectedClientNumber)
                response = await updateProgramSchedule(selectedClientNumber, scheduleId, programId, startDate, endDate, workoutsPerWeek, status);
                console.log('Updating Program Schedule:', response);

                // Delete ProgramDetails
                const deleteResponse = await deleteProgramDetails(programId);
                console.log('Deleting Program Details:', deleteResponse);

                // Redirect to Program Details
                navigate('/program-details', { state: { scheduleId: scheduleId, programId: programId } });
            } else {
                // Add Program Schedule if no schedule exists
                response = await addProgramSchedule(selectedClient, programId, startDate, endDate, workoutsPerWeek, status);
                console.log('Adding Program Schedule:', response)
            }
                // Redirect to Program Details after adding schedule
            if (response && response.ScheduleID) {
                console.log('ScheduleID:', response.ScheduleID);
                navigate('/program-details', { state: { scheduleId: response.ScheduleID, programId: programId } });
            }

        } catch (error) {
            console.error('Failed to add/update schedule:', error);
            alert('Failed to add/update schedule');
        }
    };

    // If loading, display loading message
    if (loading) {
        return <h1>Loading...</h1>;
    }

    return (
        <div>
            <h1>Program: {program?.Name}</h1>
            <h2>Select Client to assign program</h2>
            <select onChange={(event) => setSelectedClient(event.target.value)}>
                <option value="">Select a client</option>
                {clients.map((client) => (
                    <option key={client.UserID} value={client.UserID}>
                        {client.FirstName} {client.LastName}
                    </option>
                ))}
            </select>

            <div>
                <label>
                    Start Date:
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </label>
                <label>
                    End Date:
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </label>
                <label>
                    Workouts per Week:
                    <select value={workoutsPerWeek} onChange={(e) => setWorkoutsPerWeek(e.target.value)}>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                        <option value={7}>7</option>
                    </select>
                </label>
                <label>
                    Status:
                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
                </label>
            </div>

            <button onClick={handleScheduleSubmit}>Assign Program</button>
        </div>
    );
}

export default ProgramSchedule;
