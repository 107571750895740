import React, { useEffect, useState, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import { fetchTrainerAppointments, fetchAppointmentCategories, fetchTrainerClients } from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';
import './AppointmentCalendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';

// Initialize the localizer with moment.js for date handling
const localizer = momentLocalizer(moment);

const AppointmentCalendar = () => {
  // State to hold appointments, clients, categories, and loading status
  const [appointments, setAppointments] = useState([]);
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // React Router hook to navigate between routes
  const navigate = useNavigate();

  // Reference to the calendar element
  const calendarRef = useRef(null);

  // Determine if the device is a touch device
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

  // State to hold the current date in the calendar
  const [currentDate, setCurrentDate] = useState(new Date());

  // Fetch Appointment Categories
  useEffect(() => {
    const getCategories = async () => {
      const data = await fetchAppointmentCategories();
      console.log('Categories Fetched:', data);
      setCategories(data);
    };
    getCategories();
  }, []);

  // Fetch Trainer Clients
  useEffect(() => {
    const getClients = async () => {
      const trainerId = localStorage.getItem('userId');
      const data = await fetchTrainerClients(trainerId);
      console.log('Clients Fetched:', data);
      setClients(data);
    };
    getClients();
  }, []);

  // Fetch appointments from the server when the component mounts
  useEffect(() => {
    const getAppointments = async () => {
      const trainerId = localStorage.getItem('userId');
      const data = await fetchTrainerAppointments(trainerId);
      console.log('Appointments Fetched:', data);
      const formattedAppointments = data.map(app => {
        const category = categories.find(cat => cat.category_id === app.category_id);
        const client = clients.find(cli => cli.UserID === app.client_id);
        return {
          id: app.appointment_id,
          title: `${category ? category.name : 'Unknown'} - ${client ? `${client.FirstName} ${client.LastName}` : 'Unknown'}`,
          start: new Date(app.start_time),
          end: new Date(app.end_time),
        };
      });
      setAppointments(formattedAppointments);
      setIsLoading(false);
    };
    if (clients.length > 0 && categories.length > 0) {
      getAppointments();
    }
  }, [clients, categories]);

  // Handle touch events for touch devices
  useEffect(() => {
    const calendarElement = calendarRef.current;
    if (isTouchDevice && calendarElement) {
      let touchStartTime;
      let touchStartPosition;
      let touchMoved = false;

      // Handle touch start event
      const handleTouchStart = (event) => {
        if (event.touches.length === 1) {
          const touch = event.touches[0];
          touchStartTime = new Date().getTime();
          touchStartPosition = { x: touch.clientX, y: touch.clientY };
          touchMoved = false;
          console.log('Touch start detected', touchStartPosition);
        }
      };

      // Handle touch move event
      const handleTouchMove = () => {
        touchMoved = true;
      };

      // Handle touch end event
      const handleTouchEnd = (event) => {
        if (event.changedTouches.length === 1) {
          const touch = event.changedTouches[0];
          const touchEndTime = new Date().getTime();
          const touchEndPosition = { x: touch.clientX, y: touch.clientY };

          const timeDiff = touchEndTime - touchStartTime;
          const distanceMoved = Math.sqrt(
            Math.pow(touchEndPosition.x - touchStartPosition.x, 2) +
            Math.pow(touchEndPosition.y - touchStartPosition.y, 2)
          );

          console.log('Touch end detected', touchEndPosition, 'Time diff:', timeDiff, 'Distance moved:', distanceMoved);

          if (timeDiff < 300 && distanceMoved < 10 && !touchMoved) {
            const { start, end } = getSlotTimes(touch.clientX, touch.clientY, currentDate, 1); // 1 hour interval for touch devices
            if (start && end) {
              console.log('Navigating to create appointment', { start, end });
              navigate('/create-appointment', { state: { start, end } });
            } else {
              console.error('Failed to get slot times');
            }
          }
        }
      };

      // Attach touch event listeners to the calendar element
      calendarElement.addEventListener('touchstart', handleTouchStart);
      calendarElement.addEventListener('touchmove', handleTouchMove);
      calendarElement.addEventListener('touchend', handleTouchEnd);

      // Clean up event listeners on unmount
      return () => {
        calendarElement.removeEventListener('touchstart', handleTouchStart);
        calendarElement.removeEventListener('touchmove', handleTouchMove);
        calendarElement.removeEventListener('touchend', handleTouchEnd);
      };
    }
  }, [isTouchDevice, navigate, currentDate]);

  // Function to calculate slot times based on touch coordinates
  const getSlotTimes = (x, y, date, interval = 1) => { // Default interval set to 1 hour
    const calendarElement = calendarRef.current;
    const slots = calendarElement.querySelectorAll('.rbc-time-slot');
    console.log('Slots found:', slots);
    for (const slot of slots) {
      const rect = slot.getBoundingClientRect();
      if (
        x >= rect.left &&
        x <= rect.right &&
        y >= rect.top &&
        y <= rect.bottom
      ) {
        console.log('Tapped on slot:', slot);

        // Find the parent day column
        const dayColumn = slot.closest('.rbc-day-slot');
        console.log('Day column:', dayColumn);

        if (dayColumn) {
          // Get all slots in this day column
          const slotsInDay = dayColumn.querySelectorAll('.rbc-time-slot');
          const slotIndex = Array.from(slotsInDay).indexOf(slot);
          console.log('Slot index:', slotIndex);

          // Calculate the time based on the slot index
          const minutesFromMidnight = slotIndex * 30;
          const hours = Math.floor(minutesFromMidnight / 60);
          const minutes = minutesFromMidnight % 60;

          const start = moment(date).tz('America/Chicago').hours(hours).minutes(minutes).seconds(0).milliseconds(0).toDate();
          const end = new Date(start);
          end.setHours(end.getHours() + interval);

          console.log('Calculated slot times:', { start, end });
          return { start, end };
        }

        // If we couldn't find the date and time, let's log more information about the slot
        console.log('Slot details:', {
          classList: slot.classList,
          parentClassList: slot.parentElement.classList,
          grandparentClassList: slot.parentElement.parentElement.classList,
        });

        break; // Exit the loop since we found the tapped slot
      }
    }
    return { start: null, end: null };
  };

  // Handle event selection (clicking on an existing appointment)
  const handleSelectEvent = (event) => {
    navigate(`/appointment-details/${event.id}`);
  };

  return (
    <div className="appointment-list-container" ref={calendarRef}>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Calendar
          localizer={localizer}
          events={appointments}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 600 }}
          onSelectEvent={handleSelectEvent}
          defaultView="week"
          selectable={!isTouchDevice}
          onSelectSlot={({ start, end }) => {
            console.log('Mouse select slot start time:', start);
            console.log('Mouse select slot end time:', end);
            if (!isTouchDevice) {
              const adjustedEnd = new Date(start);
              adjustedEnd.setHours(start.getHours() + 1); // Set the default duration to 1 hour for mouse events
              navigate('/create-appointment', { state: { start, end: adjustedEnd } });
            }
          }}
          onNavigate={(date) => setCurrentDate(date)}
        />
      )}
    </div>
  );
};

export default AppointmentCalendar;
