import React, { useState, useEffect } from 'react';
import { 
    fetchWorkoutTemplatesForTrainer,
    archiveWorkoutTemplate,
    fetchTrainerClients,
} from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';

const WorkoutTemplates = () => {
    const [workoutTemplates, setWorkoutTemplates] = useState([]);
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [universalTemplates, setUniversalTemplates] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState('all'); // Default to 'all'
    const trainerId = localStorage.getItem('userId');
    const navigate = useNavigate();

    // Fetch Trainer Clients
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const clients = await fetchTrainerClients(trainerId);
                // Sort clients alphabetically by first name and last name
                clients.sort((a, b) => {
                    const nameA = `${a.FirstName} ${a.LastName}`;
                    const nameB = `${b.FirstName} ${b.LastName}`;
                    return nameA.localeCompare(nameB);
                });
                setClients(clients);
            } catch (error) {
                console.error(error);
            }
        };

        fetchClients();
    }, [trainerId]);

    // Fetch All Workout Templates on Mount
    useEffect(() => {
        const fetchTemplates = async () => {
            setIsLoading(true);
            setError(null);
            try {
                let templates = await fetchWorkoutTemplatesForTrainer(trainerId);
                templates = templates.filter(template => template.ProgramReserved === null);
                const universal = templates.filter(template => template.isUniversal === 1);
                const nonUniversal = templates.filter(template => template.isUniversal === 0);
                setWorkoutTemplates(nonUniversal);
                setUniversalTemplates(universal);
                // Initialize filtered templates based on selectedClient
                filterTemplates(selectedClient, nonUniversal, universal);
            } catch (err) {
                setError('Failed to fetch workout templates');
                console.error(err);
            }
            setIsLoading(false);
        };

        fetchTemplates();
    }, [selectedClient, trainerId]);

    // Filter Templates Based on Selected Client
    const filterTemplates = (client, nonUniversalTemplates, universalTemplates) => {
        let filtered = [];
        if (client === 'universal') {
            filtered = universalTemplates;
        } else if (client === 'all') {
            filtered = [...nonUniversalTemplates, ...universalTemplates];
        } else {
            filtered = nonUniversalTemplates.filter(template => template.ClientID === parseInt(client));
        }
        setFilteredTemplates(filtered);
    };

    // Group Templates by Client
    const groupTemplatesByClient = (templates) => {
        const grouped = templates.reduce((acc, template) => {
            const clientName = clients.find(client => client.UserID === template.ClientID);
            const clientFullName = clientName ? `${clientName.FirstName} ${clientName.LastName}` : 'No Client';
            if (!acc[clientFullName]) {
                acc[clientFullName] = [];
            }
            acc[clientFullName].push(template);
            return acc;
        }, {});
        return grouped;
    };

    // Handle Client Selection Change
    const handleClientChange = (e) => {
        const clientId = e.target.value;
        setSelectedClient(clientId);
        filterTemplates(clientId, workoutTemplates, universalTemplates);
    };

    // Button Handlers
    const handleCreate = () => {
        navigate('/template-creation');
    };

    const handleView = templateId => {
        navigate(`/template/${templateId}`);
    };

    const handleDelete = async templateId => {
        if (window.confirm('Are you sure you want to delete this template?')) {
            try {
                await archiveWorkoutTemplate(templateId);
                // Re-filter templates after deletion
                const updatedTemplates = workoutTemplates.filter(template => template.TemplateID !== templateId);
                setWorkoutTemplates(updatedTemplates);
                filterTemplates(selectedClient, updatedTemplates, universalTemplates);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const groupedTemplates = selectedClient === 'all' ? groupTemplatesByClient(filteredTemplates) : {};

    return (
        <div>
            <h1>Workout Templates</h1>

            <label htmlFor="client-select">Select Client:</label>
            <select
                id="client-select"
                value={selectedClient}
                onChange={handleClientChange}
            >
                <option value="all">All Templates</option>
                <option value="universal">Universal Templates</option>
                {clients.map(client => (
                    <option key={client.UserID} value={client.UserID}>
                        {client.FirstName} {client.LastName}
                    </option>
                ))}
            </select>

            {isLoading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : (
                <>
                    {selectedClient === 'all' && (
                        <>
                            {Object.keys(groupedTemplates).length > 0 ? (
                                Object.keys(groupedTemplates).map(clientName => (
                                    <div key={clientName}>
                                        <h2>{clientName}</h2>
                                        <ul>
                                            {groupedTemplates[clientName].map(template => (
                                                <li key={template.TemplateID}>
                                                    {template.Name} - {template.Description}
                                                    <button onClick={() => handleView(template.TemplateID)}>View Template</button>
                                                    <button onClick={() => handleDelete(template.TemplateID)}>Archive</button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))
                            ) : <p>No workout templates found for this selection.</p>}
                            
                            {universalTemplates.length > 0 && (
                                <div>
                                    <h2>Universal Templates</h2>
                                    <ul>
                                        {universalTemplates.map(template => (
                                            <li key={template.TemplateID}>
                                                {template.Name} - {template.Description}
                                                <button onClick={() => handleView(template.TemplateID)}>View Template</button>
                                                <button onClick={() => handleDelete(template.TemplateID)}>Archive</button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    )}

                    {selectedClient !== 'all' && filteredTemplates.length > 0 ? (
                        <ul>
                            {filteredTemplates.map(template => (
                                <li key={template.TemplateID}>
                                    {template.Name} - {template.Description}
                                    <button onClick={() => handleView(template.TemplateID)}>View Template</button>
                                    <button onClick={() => handleDelete(template.TemplateID)}>Archive</button>
                                </li>
                            ))}
                        </ul>
                    ) : selectedClient !== 'all' && <p>No workout templates found for this selection.</p>}
                </>
            )}

            <button onClick={handleCreate}>Create New Template</button>
        </div>
    );
};

export default WorkoutTemplates;
