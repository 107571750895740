// WorkoutSession.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchSessionDetails, deleteWorkoutSession, updateWorkoutSession, duplicateWorkoutSession, deactivateProgramSession } from '../../api/apiHandlers';

const WorkoutSession = () => {
    // Inside your component
    const { sessionId, clientId } = useParams();
    const [sessionDetails, setSessionDetails] = useState(null);
    const navigate = useNavigate();
    const isSuperset = (exercise, exercises) => {
        return exercises.some(e => e.SupersetID === exercise.SupersetID && e.ExerciseID !== exercise.ExerciseID);
    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
    const location = useLocation();
    const isProgramSession = location.state?.isProgramSession;
    const userType = localStorage.getItem('userType');


    useEffect(() => {
        const loadSessionDetails = async () => {
            try {
                const response = await fetchSessionDetails(sessionId);
                console.log('(WorkoutSession.js)Fetched session details:', response); // Log the fetched data
                // Process the response to group sets by exercise and SupersetID
                const exercisesWithSets = response.Exercises.reduce((acc, current) => {
                    const { ExerciseID, Name, Type, SetNumber, Reps, Weight, Distance, Time, SupersetID, Notes } = current;
                    const set = { SetNumber, Reps, Weight, Distance, Time, Notes };
                    const foundExercise = acc.find(ex => ex.ExerciseID === ExerciseID && ex.SupersetID === SupersetID);
                    if (foundExercise) {
                        foundExercise.Sets.push(set);
                    } else {
                        acc.push({
                            ExerciseID,
                            Name,
                            Type,
                            Sets: [set],
                            SupersetID,
                            OrderID: current.OrderID // Include OrderID here
                        });
                    }
                    return acc;
                }, []);

                // Sort the exercises by OrderID
                exercisesWithSets.sort((a, b) => a.OrderID - b.OrderID);

                setSessionDetails({ ...response, Exercises: exercisesWithSets });
            } catch (error) {
                console.error('Error fetching session details:', error);
            }
        };
        console.log('Session Details:', sessionDetails);
        loadSessionDetails();
    }, [sessionId]);


    const handleDuplicateSession = (sessionId) => {
        duplicateWorkoutSession(sessionId, selectedDate)
            .then(response => {
                const newSessionId = response.newSessionId; // Assuming the response contains the new session ID
                setIsModalVisible(false); // Close the modal

                // Navigate to the new duplicated session's detail page
                navigate(`/track-workout-session/${newSessionId}/${clientId}`);
            })
            .catch(err => {
                console.error('Error duplicating workout session:', err);
                alert('Failed to duplicate the session. Please try again.');
            });
    };

    console.log('Session Details:', sessionDetails);

    if (!sessionDetails) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="workout-session-container">
                <h2 className="header"> {sessionDetails.ClientFirstName} {sessionDetails.ClientLastName} </h2>
                <p className="workout-session-description"><strong>Date: </strong>{new Date(sessionDetails.Date).toLocaleDateString()}</p>
                <p className="workout-session-description"><strong>Description: </strong>{sessionDetails.Description}</p>
                <p className="workout-session-description"><strong>Session Notes: </strong>{sessionDetails.SessionNotes}</p>

                {sessionDetails.Exercises.map((exercise, index) => {
                    // Check if this is the first exercise of a superset
                    if (exercise.SupersetID && sessionDetails.Exercises.findIndex(e => e.SupersetID === exercise.SupersetID) === index) {
                        // Render the superset header and exercises
                        const exercisesInSuperset = sessionDetails.Exercises.filter(e => e.SupersetID === exercise.SupersetID);
                        return (
                            <div key={`superset-${exercise.SupersetID}`} className="superset-container">
                                <h3 className="superset-header">Superset</h3>
                                {exercisesInSuperset.map(supersetExercise => (
                                    <div key={supersetExercise.ExerciseID} className="exercise-container">
                                        <h4 className="exercise-header">{supersetExercise.Name}</h4>
                                        {/* Render the table for the superset exercise */}
                                        <table className="workout-table">
                                            <thead>
                                                <tr>
                                                    <th>Set</th>
                                                    {supersetExercise.Sets[0].Reps !== null ? <th>Reps</th> : null}
                                                    {supersetExercise.Sets[0].Weight !== null ? <th>Weight</th> : null}
                                                    {supersetExercise.Sets[0].Distance !== null ? <th>Distance</th> : null}
                                                    {supersetExercise.Sets[0].Time !== null ? <th>Time</th> : null}
                                                    {supersetExercise.Sets[0].Notes !== null ? <th>Notes</th> : null}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {supersetExercise.Sets.map((set, setIndex) => (
                                                    <tr key={setIndex}>
                                                        <td>{set.SetNumber}</td>
                                                        {set.Reps !== null ? <td>{set.Reps}</td> : null}
                                                        {set.Weight !== null ? <td>{set.Weight}</td> : null}
                                                        {set.Distance !== null ? <td>{set.Distance}</td> : null}
                                                        {set.Time !== null ? <td>{set.Time}</td> : null}
                                                        {set.Notes !== null ? <td>{set.Notes}</td> : null}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>
                        );
                    } else if (!exercise.SupersetID) {
                        // Render non-superset exercises
                        return (
                            <div key={exercise.ExerciseID} className="exercise-container">
                                <h4 className="exercise-header">{exercise.Name}</h4>
                                {/* Render the table for the non-superset exercise */}
                                <table className="workout-table">
                                    <thead>
                                        <tr>
                                            <th>Set</th>
                                            {exercise.Sets[0].Reps !== null ? <th>Reps</th> : null}
                                            {exercise.Sets[0].Weight !== null ? <th>Weight</th> : null}
                                            {exercise.Sets[0].Distance !== null ? <th>Distance</th> : null}
                                            {exercise.Sets[0].Time !== null ? <th>Time</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {exercise.Sets.map((set, setIndex) => (
                                            <tr key={setIndex}>
                                                <td>{set.SetNumber}</td>
                                                {set.Reps !== null ? <td>{set.Reps}</td> : null}
                                                {set.Weight !== null ? <td>{set.Weight}</td> : null}
                                                {set.Distance !== null ? <td>{set.Distance}</td> : null}
                                                {set.Time !== null ? <td>{set.Time}</td> : null}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        );
                    }
                    // If it is not the first exercise of a superset, we don't render it again.
                    return null;
                })}

                {!(isProgramSession && userType === 'client') && (
                    <>
                        <button onClick={() => {
                            if (window.confirm('Are you sure you want to delete this workout?')) {
                                deleteWorkoutSession(sessionDetails.SessionID, clientId)
                                deactivateProgramSession(sessionDetails.Date)
                                    .then(() => navigate(`/client-workout-sessions/${clientId}`))
                                    .catch(err => console.error('Error deleting workout session:', err));
                            }
                        }}>Delete Workout</button>
                        <button onClick={() => {
                            updateWorkoutSession(sessionDetails.SessionID)
                                .then(() => navigate(`/track-workout-session/${sessionDetails.SessionID}/${clientId}`))
                                .catch(err => console.error('Error updating workout session:', err));
                        }}>Update Workout</button>
                        <button onClick={() => setIsModalVisible(true)}>Duplicate Workout</button>
                    </>
                )}
            </div>

            {isModalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <button className="modal-close-btn" onClick={() => setIsModalVisible(false)}>&times;</button>
                        <form className="modal-form" onSubmit={(e) => { e.preventDefault(); handleDuplicateSession(sessionDetails.SessionID); }}>
                            <h2>Select a date for the duplicated session</h2>
                            <label>
                                Date:
                                <input
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    required
                                    className="modal-form-input" // Apply the same input field styling
                                />
                            </label>
                            <button type="submit" className="button">Duplicate</button>
                            <button type="button" className="button" onClick={() => setIsModalVisible(false)}>Cancel</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WorkoutSession;
