// CreateTemporaryClub.js

import React from 'react';
import { addTemporaryTestClub, deleteTemporaryTestClub } from '../../api/apiHandlers';

const CreateTemporaryClub = () => {
    const handleAddClub = async () => {
        try {
            const result = await addTemporaryTestClub();
            console.log('Temporary test club created:', result);
            alert('Temporary test club created successfully!');
        } catch (error) {
            console.error('Error creating temporary test club:', error);
            alert('Failed to create temporary test club.');
        }
    };

    const handleDeleteClub = async () => {
        try {
            const result = await deleteTemporaryTestClub();
            console.log('Temporary test club deleted:', result);
            alert('Temporary test club deleted successfully!');
        } catch (error) {
            console.error('Error deleting temporary test club:', error);
            alert('Failed to delete temporary test club.');
        }
    };

    return (
        <div>
            <h2>Manage Temporary Test Club</h2>
            <button onClick={handleAddClub}>Create Temporary Test Club</button>
            <button onClick={handleDeleteClub}>Delete Temporary Test Club</button>
        </div>
    );
};

export default CreateTemporaryClub;
