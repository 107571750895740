// apiHandlers.js

const IS_DEV = false;  // set this to true when developing at home, false otherwise

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const headers = {
    'x-api-key': API_KEY,
    'Content-Type': 'application/json',
};

//Login Function 
export async function loginUser(email, password) {
    // Attempt to retrieve an iOS app's session token from local storage
    const iosSessionToken = localStorage.getItem('iosSessionToken') || '';

    // Debugging log to see the data being sent
    console.log(`Attempting login with Email: ${email}, Password: [REDACTED], iOS Session Token: ${iosSessionToken}`);

    try {
        const response = await fetch(`${API_URL}/login`, {
            method: 'POST',
            headers: headers,
            // Include iosSessionToken in the request payload if it exists
            body: JSON.stringify({ email, password, iosSessionToken }),
        });

        if (!response.ok) {
            console.error(`Login failed with status: ${response.status}`); // Enhanced error logging
            throw new Error(`Login failed: ${response.statusText}`);
        }

        const data = await response.json();
        console.log(`Login successful: Token: ${data.token}, UserId: ${data.userId}, UserType: ${data.userType}`);
        return data;
    } catch (error) {
        console.error('Login error:', error); // Error logging for fetch issues
        throw error; // Rethrow to handle it in the component or caller function
    }
}


export async function registerUser(email, password, userType, firstName, lastName) {
    const response = await fetch(`${API_URL}/register-user`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ email, password, userType, firstName, lastName }),
    });

    if (!response.ok) {
        throw new Error('Registration failed');
    }

    return response.json();
}



// Fetch User Profile
export async function fetchUserProfile(clientId) {
    const response = await fetch(`${API_URL}/clients/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching profile failed');
    }

    return response.json();
}

// Fetch User Account Details (Email, Password, etc...)
export async function fetchClientAccountDetails(userId) {
    const response = await fetch(`${API_URL}/clients/account/${userId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching user account details failed');
    }

    return response.json();
}


// Update profile
export async function updateUserProfile(userId, profileData) {
    const response = await fetch(`${API_URL}/updateProfile`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ id: userId, ...profileData }),
    });

    if (!response.ok) {
        throw new Error('Updating profile failed');
    }

    return response.json();
}


// Delete User profile
export async function deleteUserProfile(userId) {
    const response = await fetch(`${API_URL}/deleteProfile`, {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify({ id: userId }),
    });

    if (!response.ok) {
        throw new Error('Deleting profile failed');
    }

    return response.json();
}

// Fetch Clients' Profiles
export async function fetchClientsProfiles(TrainerUserID) {
    // Append the TrainerUserID as a query parameter
    const url = new URL(`${API_URL}/clients`);
    url.searchParams.append('TrainerUserID', TrainerUserID);

    const response = await fetch(url, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching clients profiles failed');
    }

    return response.json();
}





// Create New Client Profile
export async function createClientProfile(clientData) {
    const response = await fetch(`${API_URL}/clients`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(clientData),
    });

    if (!response.ok) {
        throw new Error('Creating client profile failed');
    }

    return response.json();
}

// Set Client Profile as Archived
export async function archiveClientProfile(userId) {
    const response = await fetch(`${API_URL}/clients/archive/${userId}`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Archiving client profile failed');
    }

    return response.json();
}


// Fetch Trainer Profiles
export async function fetchTrainers() {
    try {
        const response = await fetch(`${API_URL}/users/trainers`, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch trainers');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching trainers:', error);
        throw error;
    }
}


// Fetch All Workout Sessions
export async function fetchAllWorkoutSessions() {
    const response = await fetch(`${API_URL}/workout-sessions`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching workout sessions failed');
    }

    return response.json();
}

// Fetch Workout Sessions for a Client
export async function fetchWorkoutSessions(clientId) {
    const response = await fetch(`${API_URL}/workout-sessions/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching workout sessions failed');
    }

    return response.json();
}


// Fetch Details of an Active Workout Session
export async function fetchActiveSessionDetails(sessionId) {
    const url = `${API_URL}/workout-session-active/${sessionId}`;
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers,
        });
        if (!response.ok) {
            const errorData = await response.text(); // Using text() in case the response is not in JSON format
            console.error('[Client] Error fetching active workout session details:', errorData);
            throw new Error(errorData || 'Fetching active workout session details failed');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('[Client] Error in fetchActiveSessionDetails:', error);
        throw error;
    }
}

// Update Details of an ACtive Workout Session
export async function updateActiveSessionDetails(sessionId, sessionDetails) {
    const url = `${API_URL}/update-workout-session-active/${sessionId}`;

    const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(sessionDetails),
    });

    return await response.json();
}


// Assign Trainer to Client
export async function assignTrainerToClient(clientUserId, trainerUserId) {
    const response = await fetch(`${API_URL}/assign-trainer`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ clientUserId, trainerUserId }),
    });

    if (!response.ok) {
        throw new Error('Failed to assign trainer');
    }

    return response.json();
}

// Fetch Details of a Specific Workout Session
export async function fetchSessionDetails(sessionId) {
    const response = await fetch(`${API_URL}/workout-session/${sessionId}`, {

        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching workout session details failed');
    }

    const data = await response.json();


    return data;
}

// Fetch Session Exercises
export const fetchSessionExercises = async (sessionId) => {
    const response = await fetch(`${API_URL}/sessionExercises/${sessionId}`, {
        method: 'GET',
        headers: headers
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}


// Function to start a new workout session for a client
export async function startNewWorkoutSession(clientId, date) {
    const response = await fetch(`${API_URL}/start-workout-session`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ UserID: clientId, Date: date }),
    });

    if (!response.ok) {
        throw new Error('Starting new workout session failed');
    }

    return response.json();
}

// Fetch Exercises  
export async function fetchExercises(trainerId) {
    const response = await fetch(`${API_URL}/exercises/${trainerId}`, {
        method: 'GET',
        headers: headers
    });

    if (!response.ok) {
        throw new Error('Fetching exercises failed');
    }

    return response.json();
}

// Archive Exercise
export async function archiveExercise(exerciseId) {
    const response = await fetch(`${API_URL}/archive-exercise/${exerciseId}`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to archive exercise');
    }

    return response.json();
}



// Fetch a Single Exercise
export async function fetchExercise(exerciseId) {
    const response = await fetch(`${API_URL}/exercise/${exerciseId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching exercise failed');
    }

    return response.json();
}



// Create New Exercise
export async function createNewExercise(exerciseData) {
    const response = await fetch(`${API_URL}/exercises`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(exerciseData)
    });

    if (!response.ok) {
        throw new Error('Creating new exercise failed');
    }

    return response.json();
}

// Update an Exercise
export async function updateExercise(exerciseId, exerciseData, file) {
    console.log('Received Data in updateExercise:', exerciseData);
    const url = `${API_URL}/update-exercise/${exerciseId}`;

    const formData = new FormData();
    formData.append('Name', exerciseData.Name);
    formData.append('Description', exerciseData.Description);
    formData.append('TracksTime', exerciseData.TracksTime ? 1 : null);
    formData.append('TracksDistance', exerciseData.TracksDistance ? 1 : null);
    formData.append('TracksReps', exerciseData.TracksReps ? 1 : null);
    formData.append('TracksWeight', exerciseData.TracksWeight ? 1 : null);
    formData.append('TrainerUserID', exerciseData.TrainerUserID);
    formData.append('isUnilateral', exerciseData.isUnilateral);
    formData.append('isBilateral', exerciseData.isBilateral);
    formData.append('isCardio', exerciseData.isCardio);
    formData.append('PrimaryMuscleGroupID', exerciseData.PrimaryMuscleGroupID);
    formData.append('AssistingMuscleGroupID', exerciseData.AssistingMuscleGroupID);
    formData.append('EquipmentID', exerciseData.EquipmentID);

    if (file) {
        formData.append('image', file);
    }

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'x-api-key': API_KEY
            },
            body: formData
        });

        if (!response.ok) {
            const errorBody = await response.json();
            throw new Error(errorBody.message || 'Failed to update exercise');
        }

        return response.json();
    } catch (error) {
        console.error('Error updating exercise:', error);
        throw error;
    }
}


// Merge Exercises
export async function mergeExercises(sourceExerciseId, targetExerciseId) {
    const url = `${API_URL}/merge-exercises`;

    const requestBody = {
        sourceExerciseId,
        targetExerciseId
    };

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(requestBody),
        });

        if (!response.ok) {
            const errorBody = await response.json();
            throw new Error(errorBody.message || 'Failed to merge exercises');
        }

        return response.json();
    } catch (error) {
        console.error('Error merging exercises:', error);
        throw error;
    }
}

// Create New Exercise with Image
export async function createNewExerciseWithImage(exerciseData, file) {
    const formData = new FormData();
    formData.append('image', file);

    // Append exercise data to the form data
    Object.keys(exerciseData).forEach(key => {
        formData.append(key, exerciseData[key]);
    });

    const url = `${API_URL}/exercises`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'x-api-key': API_KEY
            },
            body: formData,
        });

        if (!response.ok) {
            const errorBody = await response.json();
            throw new Error(errorBody.message || 'Creating new exercise failed');
        }

        return response.json();
    } catch (error) {
        console.error('Error creating new exercise:', error);
        throw error;
    }
}


// Upload Exercise Images
export async function uploadExerciseImage(file) {
    const formData = new FormData();
    formData.append('image', file);

    const url = `${API_URL}/upload`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'x-api-key': API_KEY
            },
            body: formData,
        });

        if (!response.ok) {
            const errorBody = await response.json();
            throw new Error(errorBody.message || 'Uploading exercise image failed');
        }

        return response.json();
    } catch (error) {
        console.error('Error uploading exercise image:', error);
        throw error;
    }
}




// Save a workout session
export async function saveWorkoutSession(sessionId, exercises) {
    // Validate exercises data
    for (let exercise of exercises) {
        if (!exercise.ExerciseID || !Array.isArray(exercise.sets)) {
            console.error('Invalid exercise data: Missing ExerciseID or sets not an array', exercise);
            return; // or return a default value or an error message
        }
        for (let set of exercise.sets) {
            // Replace empty strings with '0' for all fields in set
            for (let key in set) {
                if (set[key] === '') {
                    set[key] = '0';
                }
            }

            // Check if all fields are '0'
            if (Object.values(set).every(value => value === '0')) {
                console.error('Invalid set data: All fields are empty', set);
                return; // or return a default value or an error message
            }
        }
    }

    const response = await fetch(`${API_URL}/save-workout-session/${sessionId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ exercises }),
    });
    return response.json();
}

// Submit a workout session
export async function submitWorkoutSession(sessionId, exercises) {


    const response = await fetch(`${API_URL}/submit-workout-session/${sessionId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ exercises }),
    });

    if (!response.ok) {
        throw new Error('Saving workout session failed');
    }

    return response.json();
}

// Mark a workout session as finished
export async function finishWorkoutSession(sessionId) {
    const response = await fetch(`${API_URL}/workout-session/${sessionId}/finish`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to mark workout session as finished');
    }

    return response.json();
}

// Delete a workout session
export async function deleteWorkoutSession(sessionId, clientId) {
    const response = await fetch(`${API_URL}/delete-workout-session/${sessionId}`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to delete workout session');
    }

    await response.json();
    return clientId;
}

// Update a workout session
export async function updateWorkoutSession(sessionId) {
    const response = await fetch(`${API_URL}/update-workout-session/${sessionId}`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to update workout session');
    }

    return response.json();
}

// Duplicate a Workout Session
export async function duplicateWorkoutSession(sessionId, date) {
    const url = `${API_URL}/duplicate-session/${sessionId}`;

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ date }), // Send the date in the request body
        });

        if (!response.ok) {
            const errorData = await response.text();
            console.error('[Client] Error duplicating workout session:', errorData);
            throw new Error(errorData || 'Duplicating workout session failed');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('[Client] Error in duplicateWorkoutSession:', error);
        throw error;
    }
}


// Fetch Exercise History for a Specific Exercise
export async function fetchExerciseHistory(userId, exerciseId) {
    try {
        const response = await fetch(`${API_URL}/client/${userId}/exercise-history/${exerciseId}`, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch exercise history');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching exercise history:', error);
        throw error;
    }
}

// Fetch Recent Exercises for a User
export async function fetchRecentExercises(userId, sessionId) {
    const response = await fetch(`${API_URL}/recent-exercises/${userId}/${sessionId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        console.error('Fetching recent exercises failed with status:', response.status); // Enhanced error logging
        throw new Error('Fetching recent exercises failed');
    }

    return response.json();
}



export async function fetchClientExerciseHistory(clientId, exerciseName) {
    try {
        const response = await fetch(`${API_URL}/exercise-history/${clientId}?exerciseName=${encodeURIComponent(exerciseName)}`, {
            method: 'GET',
            headers: headers,
        });

        if (!response.ok) {
            throw new Error('Failed to fetch client exercise history');
        }

        return response.json();
    } catch (error) {
        console.error('Error fetching client exercise history:', error);
        throw error;
    }
}

// ======================= Body Metrics =======================

// Update Client Body Metrics
export async function addClientBodyMetrics(clientId, metrics) {
    const response = await fetch(`${API_URL}/client-body-metrics`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            UserID: clientId,
            DateRecorded: metrics.DateRecorded,
            Weight: metrics.Weight,
            BodyFatPercentage: metrics.BodyFatPercentage,
            LeanBodyMass: metrics.LeanBodyMass,
            BodyFatMass: metrics.BodyFatMass
        }),
    });
    if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Adding client body metrics failed');
    }
    return response.json();
}


// Fetch Client Body Metrics
export async function fetchClientBodyMetrics(clientId) {
    const response = await fetch(`${API_URL}/client-body-metrics/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching client body metrics failed');
    }

    return response.json();
}


// Fetch Muscle Groups
export async function fetchMuscleGroups() {
    const response = await fetch(`${API_URL}/muscle-groups`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching muscle groups failed');
    }

    return response.json();
}

// Fetch Exercise Equipment
export async function fetchExerciseEquipment() {
    const response = await fetch(`${API_URL}/exercise-equipment`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching exercise equipment failed');
    }

    return response.json();
}

// Fetch User Volume
export async function fetchUserVolume(userId, timeframe) {
    const response = await fetch(`${API_URL}/user-volume/${userId}?timeframe=${timeframe}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching user data failed');
    }

    return response.json();
}


// ========== Admin Handlers ==========

// Fetch ClubId
export async function fetchClubId(adminId) {
    const response = await fetch(`${API_URL}/club/${adminId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching club ID failed');
    }

    return response.json();
}

// Fetch All Trainer Users
export async function fetchClubTrainers(clubId) {
    const response = await fetch(`${API_URL}/users/trainers/${clubId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching trainers failed');
    }

    return response.json();
}

// Fetch Trainer Sessions
export async function fetchTrainerSessions(trainerId) {
    const response = await fetch(`${API_URL}/trainer-sessions/${trainerId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching trainer sessions failed');
    }

    return response.json();
}


// Fetch and Compare Trainer Sessions
export async function fetchAndCompareTrainerSessions(trainerId, timeFrame) {
    const url = `${API_URL}/compare-trainer-sessions/${trainerId}?timeframe=${encodeURIComponent(timeFrame)}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching and comparing trainer sessions failed');
    }

    return response.json();
}



// Fetch Trainer Clients
export async function fetchTrainerClients(trainerId) {
    const response = await fetch(`${API_URL}/trainer-clients/${trainerId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching trainer clients failed');
    }

    return response.json();
}

// Fetch Completed Sessions
export async function fetchCompletedSessions(clientId) {
    const response = await fetch(`${API_URL}/completed-sessions/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching workout sessions failed');
    }

    return response.json();
}

// Fetch and Compare Client's Sessions
export async function fetchAndCompareClientSessions(clientId, timeFrame) {
    const url = `${API_URL}/compare-client-sessions/${clientId}?timeframe=${encodeURIComponent(timeFrame)}`;
    const response = await fetch(url, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching and comparing sessions failed');
    }

    return response.json();
}

// Fetch Club Clients
export async function fetchClubClients() {

    const clubId = localStorage.getItem('clubId');

    // Check if the clubId is not null
    if (!clubId) {
        console.error('Club ID is missing in local storage');
        throw new Error('Club ID is required and missing in local storage');
    }

    const response = await fetch(`${API_URL}/club-clients/${clubId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Fetching club clients failed:', errorMessage);
        throw new Error('Fetching club clients failed');
    }

    return response.json();
}

// Fetch Client's Trainer
export async function fetchClientTrainer(clientId) {
    const response = await fetch(`${API_URL}/client-trainer/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching client trainer failed');
    }

    return response.json();
}


// =========  Email Verification Handlers =========

export async function emailRegistration(email, userType, firstName, lastName, clubId) {
    const response = await fetch(`${API_URL}/email-verification`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ email, userType, firstName, lastName, clubId }), // Include clubId in the request body
    });

    if (!response.ok) {
        const errorData = await response.json(); // Assuming the server responds with JSON
        throw new Error(errorData.message || 'Registration failed');
    }

    return response.json();
}

// Creat Client Login Account
export async function createClientLoginAccount(userId, email) {
    const response = await fetch(`${API_URL}/create-client-login-account`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ userId, email }),
    });

    if (!response.ok) {
        const errorData = await response.json(); // Assuming the server responds with JSON
        throw new Error(errorData.message || 'Account creation failed');
    }

    return response.json();
}


export async function verifyEmailAndSetPassword(token, password) {
    const response = await fetch(`${API_URL}/verify-email`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ token, password }),
    });

    if (!response.ok) {
        const errorData = await response.json(); // Assuming the server responds with JSON
        throw new Error(errorData.message || 'Email verification or password setting failed');
    }

    return response.json();
}


// Import JSON Exercises when user logs in
export async function importExercises(userId, exercises) {
    return new Promise(async (resolve, reject) => {
        try {
            const checkResponse = await fetch(`${API_URL}/check-exercises`, {
                method: 'POST',
                headers,
                body: JSON.stringify({ userId, exercises }),
            });

            if (!checkResponse.ok) {
                console.error(`Exercise check failed with status: ${checkResponse.status}`);
                throw new Error(`Exercise check failed: ${checkResponse.statusText}`);
            }

            const checkData = await checkResponse.json();

            if (checkData.message === "Exercises do not exist, import process can begin.") {
                const importResponse = await fetch(`${API_URL}/import-exercises`, {
                    method: 'POST',
                    headers,
                    body: JSON.stringify({ userId, exercises }),
                });

                // Even though the server responds immediately that the process has begun,
                // we should only resolve with the importing message if the response is successful.
                if (!importResponse.ok) {
                    console.error(`Exercise import request failed with status: ${importResponse.status}`);
                    throw new Error(`Exercise import failed: ${importResponse.statusText}`);
                }

                // Assuming the fetch succeeds, indicating the import process has begun.
                resolve({ message: 'Importing exercises, please wait...', importing: true });
            } else {
                // If the check indicates no import is necessary, resolve accordingly.
                resolve({ message: "No import necessary.", importing: false });
            }
        } catch (error) {
            console.error('Exercise import error:', error);
            reject(error);
        }
    });
}

// =========  Workout Programs =========

export async function fetchAllWorkoutPrograms() {
    const response = await fetch(`${API_URL}/workout-programs`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workout programs');
    }

    return response.json();
}

export async function fetchWorkoutProgramsForTrainer(trainerId) {
    const response = await fetch(`${API_URL}/workout-programs/trainer/${trainerId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workout programs for trainer');
    }

    const data = await response.json();
    return Array.isArray(data.programs) ? data.programs : [];
}

export async function fetchSpecificWorkoutProgram(programId) {
    const response = await fetch(`${API_URL}/workout-programs/${programId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch specific workout program');
    }

    return response.json();
}

export async function fetchAllWorkoutTemplates() {
    const response = await fetch(`${API_URL}/workout-templates`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workout templates');
    }

    return response.json();
}

export async function createWorkoutProgram(postData) {
    // Destructure the postData object to extract name, description, and trainerId
    const { name, description, trainerId } = postData;

    const response = await fetch(`${API_URL}/create-workout-program`, {
        method: 'POST',
        headers: headers,  // Ensure headers include 'Content-Type': 'application/json'
        body: JSON.stringify({ name, description, trainerId })
    });

    if (!response.ok) {
        throw new Error('Failed to create workout program');
    }

    return response.json();
}


export async function updateWorkoutProgram(programId, name, description) {
    const response = await fetch(`${API_URL}/workout-programs/${programId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ name, description })
    });

    if (!response.ok) {
        throw new Error('Failed to update workout program');
    }

    return response.json();
}

export async function deleteWorkoutProgram(programId) {
    const response = await fetch(`${API_URL}/workout-programs/${programId}`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to delete workout program');
    }

    return response.json();
}


export async function fetchWorkoutTemplatesForTrainer(trainerId) {
    const response = await fetch(`${API_URL}/workout-templates/trainer/${trainerId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workout templates for trainer');
    }

    const data = await response.json();
    return data.templates || data;
}

export async function fetchWorkoutTemplatesForClient(clientId) {
    const response = await fetch(`${API_URL}/workout-templates/client/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch workout templates for client');
    }

    const data = await response.json();
    return data.templates || data;
}


export async function fetchSpecificWorkoutTemplate(templateId) {
    const response = await fetch(`${API_URL}/workout-templates/${templateId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch specific workout template');
    }

    return response.json();
}

export async function createWorkoutTemplate(templateData) {
    const response = await fetch(`${API_URL}/workout-templates`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(templateData)
    });

    if (!response.ok) {
        throw new Error('Failed to create workout template');
    }

    return response.json();
}

export async function updateWorkoutTemplate(updatedTemplate) {
    const response = await fetch(`${API_URL}/workout-templates/${updatedTemplate.templateId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(updatedTemplate),
    });

    if (!response.ok) {
        throw new Error('Failed to update workout template');
    }

    return response.json();
}





export async function deleteWorkoutTemplate(templateId) {
    const response = await fetch(`${API_URL}/workout-templates/${templateId}`, {
        method: 'DELETE',
        headers: headers
    });

    if (!response.ok) {
        throw new Error('Failed to delete workout template');
    }

    return response.json();
}

export async function archiveWorkoutTemplate(templateId) {
    const response = await fetch(`${API_URL}/archive-workout-template/${templateId}`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to archive workout template');
    }

    return response.json();
}

export async function fetchTemplateExercises(templateId) {
    const response = await fetch(`${API_URL}/workout-templates/${templateId}/exercises`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch exercises for template');
    }

    return response.json();
}

export async function addProgramExercisesToTemplate(templateId, exercises) {
    try {
        const response = await fetch(`${API_URL}/workout-templates/${templateId}/exercises`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ exercises }),
        });

        if (!response.ok) {
            throw new Error('Failed to add exercises to template');
        }

        return response.json();
    } catch (error) {
        console.error('Error in addProgramExercisesToTemplate:', error);
        throw error; // re-throw the error so it can be caught and handled by the calling code
    }
}

export async function updateProgramExercises(templateId, exercises) {
    try {
        const response = await fetch(`${API_URL}/workout-templates/${templateId}/exercises`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({ exercises }),
        });

        if (!response.ok) {
            throw new Error('Failed to update exercises in template');
        }

        return response.json();
    } catch (error) {
        console.error('Error in updateProgramExercisesInTemplate:', error);
        throw error; // re-throw the error so it can be caught and handled by the calling code
    }
}


export async function deleteExercisesFromTemplate(templateId) {
    const response = await fetch(`${API_URL}/workout-templates/${templateId}/exercises`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to delete exercises from template');
    }

    return response.json();
}

export async function fetchProgramDetails(programId) {
    const response = await fetch(`${API_URL}/program-details/${programId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch program details');
    }

    return response.json();
}

export async function fetchProgramScheduleForClient(clientId) {
    const response = await fetch(`${API_URL}/client-program-schedule/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch program details');
    }

    return response.json();
}

export async function addProgramDetail(programId, templateId, clientId, scheduledDate) {
    const response = await fetch(`${API_URL}/program-details`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ programId, templateId, clientId, scheduledDate }),
    });

    if (!response.ok) {
        throw new Error('Failed to add program detail');
    }

    return response.json();
}

export async function deleteProgramDetails(programDetailsId) {
    const response = await fetch(`${API_URL}/program-details/${programDetailsId}`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to delete program detail');
    }

    return response.json();
}

export async function updateProgramDetail(programDetailsId, programId, templateId, clientId) {
    const response = await fetch(`${API_URL}/program-details/${programDetailsId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ programId, templateId, clientId }),
    });

    if (!response.ok) {
        throw new Error('Failed to update program detail');
    }

    return response.json();
}

export async function duplicateWorkoutProgram(clientId, programId) {
    const response = await fetch(`${API_URL}/duplicate-workout-program`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ clientId, programId }),
    });

    if (!response.ok) {
        throw new Error('Failed to duplicate workout program');
    }

    return response.json();
}

export async function activateProgramSession(programDetailsId) {
    const response = await fetch(`${API_URL}/program-details/${programDetailsId}/activate`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to activate program session');
    }

    return response.json();
}

export async function deactivateProgramSession(scheduledDate) {
    const url = `${API_URL}/program-details/${scheduledDate}/deactivate`;
    console.log('Making request to:', url, 'with headers:', headers);

    const response = await fetch(url, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to deactivate program session');
    }

    return response.json();
}

export async function rescheduleProgramSession(scheduledDate, newScheduledDate) {
    const response = await fetch(`${API_URL}/program-details/${scheduledDate}/reschedule`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ newScheduledDate }),
    });

    if (!response.ok) {
        throw new Error('Failed to reschedule program session');
    }

    return response.json();
}

export async function markAsProgramSession(sessionId) {
    const response = await fetch(`${API_URL}/workout-sessions/${sessionId}/program-session`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to mark the session as a program session');
    }

    return response.json();
}

export async function fetchProgramSchedule(scheduleId) {
    const response = await fetch(`${API_URL}/client-schedules/${scheduleId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch client schedules');
    }

    return response.json();
}

export async function fetchProgramScheduleProgramId(programId) {
    const response = await fetch(`${API_URL}/client-schedules/program/${programId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch client schedules');
    }

    return response.json();
}

export async function addProgramSchedule(clientId, programId, startDate, endDate, recurrence, status) {
    const response = await fetch(`${API_URL}/client-schedules`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ clientId, programId, startDate, endDate, recurrence, status }),
    });

    if (!response.ok) {
        throw new Error('Failed to add client schedule');
    }

    return response.json();
}

export async function updateProgramSchedule(clientId, scheduleId, programId, startDate, endDate, recurrence, status) {
    const response = await fetch(`${API_URL}/client-schedules/${scheduleId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify({ clientId, programId, startDate, endDate, recurrence, status }),
    });

    if (!response.ok) {
        throw new Error('Failed to update client schedule');
    }

    return response.json();
}

export async function deleteProgramSchedule(scheduleId) {
    const response = await fetch(`${API_URL}/client-schedules/${scheduleId}`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to delete client schedule');
    }

    return response.json();
}

// =========  MasterUser Handlers =========

export async function fetchAllClubInformation() {
    const response = await fetch(`${API_URL}/club-information`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch club information');
    }

    return response.json();
}

export async function fetchClubs() {
    const response = await fetch(`${API_URL}/get-clubs`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to fetch clubs');
    }

    return response.json();
}

export async function createClub(clubData) {
    const response = await fetch(`${API_URL}/create-club`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(clubData),
    });

    if (!response.ok) {
        throw new Error('Failed to create club');
    }

    return response.json();
}

export async function addTemporaryTestClub() {
    const response = await fetch(`${API_URL}/create-temporary-test-club`, {
        method: 'POST',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to create temporary test club');
    }

    return response.json();
}

export async function deleteTemporaryTestClub() {
    const response = await fetch(`${API_URL}/delete-temporary-test-club`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Failed to delete temporary test club');
    }

    return response.json();
}

export async function registerProspect(email) {
    const response = await fetch(`${API_URL}/register-prospect`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ email }),
    });

    if (!response.ok) {
        throw new Error('Failed to register prospect');
    }

    return response.json();
}

export const loginUserWithToken = async (token) => {
    const response = await fetch(`${API_URL}/login-with-token`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ token }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to login with token');
    }

    return response.json();
};

// Create a new appointment
export async function createAppointment(appointmentData) {
    const response = await fetch(`${API_URL}/appointments`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(appointmentData),
    });

    if (!response.ok) {
        throw new Error('Creating appointment failed');
    }

    return response.json();
}

// Get all appointments
export async function fetchAllAppointments() {
    const response = await fetch(`${API_URL}/appointments`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching all appointments failed');
    }

    return response.json();
}

// Get appointments for a specific client
export async function fetchClientAppointments(clientId) {
    const response = await fetch(`${API_URL}/appointments/client/${clientId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching client appointments failed');
    }

    return response.json();
}

// Get appointments for a specific trainer
export async function fetchTrainerAppointments(trainerId) {
    const response = await fetch(`${API_URL}/appointments/trainer/${trainerId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching trainer appointments failed');
    }

    return response.json();
}

// Fetch a specific appointment by ID
export async function fetchAppointment(appointmentId) {
    const response = await fetch(`${API_URL}/appointments/${appointmentId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching appointment failed');
    }

    return response.json();
}


// Update an appointment
export async function updateAppointment(appointmentId, appointmentData) {
    const response = await fetch(`${API_URL}/appointments/${appointmentId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(appointmentData),
    });

    if (!response.ok) {
        throw new Error('Updating appointment failed');
    }

    return response.json();
}

// Delete an appointment
export async function deleteAppointment(appointmentId) {
    const response = await fetch(`${API_URL}/appointments/${appointmentId}`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Deleting appointment failed');
    }

    return response.json();
}

// Retrieve Appointment Categories
export async function fetchAppointmentCategories() {
    const response = await fetch(`${API_URL}/appointmentCategories`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching appointment categories failed');
    }

    return response.json();
}

// Get trainer availability
export async function fetchTrainerAvailability(trainerId) {
    const response = await fetch(`${API_URL}/trainer-availability/${trainerId}`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching trainer availability failed');
    }

    return response.json();
}

// Set trainer availability
export async function setTrainerAvailability(availabilityData) {
    const response = await fetch(`${API_URL}/trainer-availability`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(availabilityData),
    });

    if (!response.ok) {
        throw new Error('Setting trainer availability failed');
    }

    return response.json();
}

// Update trainer availability
export async function updateTrainerAvailability(availabilityId, availabilityData) {
    const response = await fetch(`${API_URL}/trainer-availability/${availabilityId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(availabilityData),
    });

    if (!response.ok) {
        throw new Error('Updating trainer availability failed');
    }

    return response.json();
}

// Delete trainer availability
export async function deleteTrainerAvailability(availabilityId) {
    const response = await fetch(`${API_URL}/trainer-availability/${availabilityId}`, {
        method: 'DELETE',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Deleting trainer availability failed');
    }

    return response.json();
}

// Create a new conversation
export async function createConversation(trainer_id, client_id) {
    const response = await fetch(`${API_URL}/conversations`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ trainer_id, client_id }),
    });

    if (!response.ok) {
        throw new Error('Creating conversation failed');
    }

    return response.json();
}

// Send a message in a conversation
export async function sendMessage(conversation_id, sender_id, message_text, attachment_url, attachment_type, workout_template_id, workout_program_id) {
    const response = await fetch(`${API_URL}/messages`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
            conversation_id,
            sender_id,
            message_text,
            attachment_url,
            attachment_type,
            workout_template_id,
            workout_program_id,
        }),
    });

    if (!response.ok) {
        throw new Error('Sending message failed');
    }

    return response.json();
}

// Get all messages in a conversation
export async function fetchMessages(conversation_id) {
    const response = await fetch(`${API_URL}/conversations/${conversation_id}/messages`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching messages failed');
    }

    return response.json();
}

// Get all conversations for a user
export async function fetchConversations(user_id) {
    const response = await fetch(`${API_URL}/users/${user_id}/conversations`, {
        method: 'GET',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Fetching conversations failed');
    }

    return response.json();
}

// Mark a message as read
export async function markMessageAsRead(message_id) {
    const response = await fetch(`${API_URL}/messages/${message_id}/read`, {
        method: 'PUT',
        headers: headers,
    });

    if (!response.ok) {
        throw new Error('Marking message as read failed');
    }

    return response.json();
}
