// RegisterProspect.js

import React, { useState } from 'react';
import { registerProspect } from '../../api/apiHandlers';
import mainLogo from '../../assets/mainLogo.png';

const RegisterProspect = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        try {
            const response = await registerProspect(email);

            if (response.success) {
                setSuccess('Access link sent successfully. Please check your email.');
            } else {
                setError('Failed to register prospect. Please try again.');
            }
        } catch (error) {
            console.error('Registration failed:', error);
            setError('Registration failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={styles.registerProspectScreen}>
            <img src={mainLogo} alt="Main Logo" style={{ width: '15rem', marginBottom: '20px' }} />
            {loading ? (
                <div style={styles.spinner}></div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2>Register for Test Environment</h2>
                    {error && <div style={styles.errorMessage}>{error}</div>}
                    {success && <div style={styles.successMessage}>{success}</div>}
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <button type="submit" style={styles.button}>Register</button>
                </form>
            )}
        </div>
    );
};

export default RegisterProspect;

const styles = {
    registerProspectScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'transparent'
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        justifyContent: 'center',
    },
    input: {
        width: '200px',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '10px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    errorMessage: {
        color: 'red',
        marginBottom: '15px',
    },
    successMessage: {
        color: 'green',
        marginBottom: '15px',
    },
    spinner: {
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderTopColor: '#007bff',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        animation: 'spin 1s linear infinite',
    }
};