import React, { useState, useEffect } from 'react';
import { fetchClientsProfiles, fetchWorkoutSessions,  } from '../../api/apiHandlers';
import { useNavigate } from 'react-router-dom';

const ClientScreen = () => {
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const trainerId = localStorage.getItem('userId');
    const [workoutSessions, setWorkoutSessions] = useState([]);
    const [finishedSessions, setFinishedSessions] = useState([]);
    const [unfinishedSessions, setUnfinishedSessions] = useState([]);
    const [isLoading , setIsLoading] = useState(true);


    // Print clients to console
    console.log('Clients:', clients);

    // Fetch Clients
    // Fetch Clients
useEffect(() => {
    const fetchAndSetClients = async () => {
        setIsLoading(true);
        const TrainerUserID = trainerId;
        try {
            const response = await fetchClientsProfiles(TrainerUserID);
            response.sort((a, b) => {
                const nameA = `${a.FirstName} ${a.LastName}`.toUpperCase();
                const nameB = `${b.FirstName} ${b.LastName}`.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            setClients(response);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
        setIsLoading(false);
    };
    fetchAndSetClients();
}, [trainerId]);

// Fetch Workout Sessions for Clients
useEffect(() => {
    const fetchWorkoutSessionsForClients = async () => {
        setIsLoading(true);
        const clientIds = clients.map(client => client.UserID);
        try {
            const responses = await Promise.all(clientIds.map(id => fetchWorkoutSessions(id)));
            console.log('Workout Sessions:', responses);

            // Flatten the array
            const allWorkoutSessions = [].concat(...responses);

            // Sort the workout sessions by date
            const sortedWorkoutSessions = allWorkoutSessions.sort((a, b) => new Date(a.Date) - new Date(b.Date));
            setWorkoutSessions(sortedWorkoutSessions);
            console.log('Sorted Workout Sessions:', sortedWorkoutSessions);

            // Create two arrays based on the IsFinished property
            const finishedSessions = sortedWorkoutSessions.filter(session => session.IsFinished === 1);
            console.log('Finished Sessions:', finishedSessions);
            const unfinishedSessions = sortedWorkoutSessions.filter(session => session.IsFinished === 0);
            console.log('Unfinished Sessions:', unfinishedSessions);

            // Update state
            setFinishedSessions(finishedSessions);
            setUnfinishedSessions(unfinishedSessions);
        } catch (error) {
            console.error('Error fetching workout sessions:', error);
        }
        setIsLoading(false);
    };

    fetchWorkoutSessionsForClients();
}, [clients]);







    // Navigate to Client Workout Sessions
    const navigateToClientWorkoutSessions = (clientId) => {
        navigate(`/client-workout-sessions/${clientId}`); // Update the path as per your routing setup
    };

    // Navigate to Create Client
    const navigateToCreateClient = () => {
        navigate('/create-client'); // Replace with the actual path to your create client screen
    };




    return (
    <div className="container">
        <h1 className="header">Clients</h1>
        <div className="client-list">
            {clients.map(client => {
                const clientUnfinishedSessions = unfinishedSessions.filter(session => session.UserID === client.UserID);
                const clientFinishedSessions = finishedSessions.filter(session => session.UserID === client.UserID);
                const mostRecentFinishedSession = clientFinishedSessions.sort((a, b) => new Date(b.Date) - new Date(a.Date))[0];

                return (
                    <div
                        key={client.UserID}
                        onClick={() => navigateToClientWorkoutSessions(client.UserID)}
                        className="client-item"
                    >
                        <div className="client-avatar">
                            {/* Placeholder for client avatar */}
                        </div>
                        <div className="client-info">
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    <h2>{client.FirstName} {client.LastName}</h2>
                                    {clientUnfinishedSessions.length > 0 ? (
                                        <>
                                            <p style={{ color: 'red' }}>
                                                {clientUnfinishedSessions.length} {clientUnfinishedSessions.length === 1 ? 'session' : 'sessions'} in progress
                                            </p>
                                            {clientUnfinishedSessions.map((session, index) => (
                                                <p key={index}>{new Date(session.Date).toLocaleDateString()}</p>
                                            ))}
                                        </>
                                    ) : (
                                        mostRecentFinishedSession ?
                                        <>
                                            <p>Last completed session:</p>
                                            <p>{new Date(mostRecentFinishedSession.Date).toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
                                        </> :
                                        <p>No sessions yet.</p>
                                    )}
                                    {/* Additional client info here */}
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
        <div className="button-container">
            <button className="button" onClick={navigateToCreateClient}>
                Create New Client
            </button>
        </div>
    </div>
);
};

export default ClientScreen;
