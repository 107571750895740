import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    fetchSpecificWorkoutProgram,
    fetchProgramDetails,
    fetchProgramScheduleProgramId,
    fetchSpecificWorkoutTemplate,
    fetchClientAccountDetails,
    duplicateWorkoutProgram,
    fetchClientsProfiles
} from '../../api/apiHandlers';

const ProgramTemplateView = () => {

    const location = useLocation();
    const programId = location.state?.programId;
    const [program, setProgram] = useState(null);
    const [programDetails, setProgramDetails] = useState(null);
    const [programSchedule, setProgramSchedule] = useState(null);
    const [loading, setLoading] = useState(true);
    const [programLoaded, setProgramLoaded] = useState(false);
    const [programDetailsLoaded, setProgramDetailsLoaded] = useState(false);
    const [programScheduleLoaded, setProgramScheduleLoaded] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [selectedDays, setSelectedDays] = useState({});
    const [client, setClient] = useState([]);
    const [trainerClients, setTrainerClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();



    // Fetch Program
    useEffect(() => {
        const fetchProgram = async () => {
            try {
                const fetchedProgram = await fetchSpecificWorkoutProgram(programId);
                setProgram(fetchedProgram);
                console.log('Program:', fetchedProgram);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch program:', err);
            }
            setProgramLoaded(true);
        }

        if (programId) {
            fetchProgram();
        }
    }
        , [programId]);

    // Fetch Program Details
    useEffect(() => {
        const fetchSchedule = async () => {
            try {
                const details = await fetchProgramScheduleProgramId(programId);
                setProgramDetails(details);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching program schedule:', error);
                setLoading(false);
            }
            setProgramDetailsLoaded(true);
        };

        if (programId) {
            fetchSchedule();
        }

    }, [programId]);

    // Fetch Program Schedule
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                const schedule = await fetchProgramDetails(programId);
                setProgramSchedule(schedule);
                console.log('Schedule:', schedule);
            } catch (error) {
                console.error(error);
            }
            setProgramScheduleLoaded(true);
        };

        if (programId) {
            fetchDetails();
        }
    }, [programId]);

    // Fetch Templates
    useEffect(() => {
        const fetchTemplates = async () => {
            const promises = programSchedule
                .sort((a, b) => new Date(a.ScheduledDate) - new Date(b.ScheduledDate))
                .slice(0, programDetails[0].Recurrence)
                .map(detail => fetchSpecificWorkoutTemplate(detail.TemplateID));

            const templates = await Promise.all(promises);
            setTemplates(templates);
        };

        if (programLoaded && programDetailsLoaded && programScheduleLoaded) {
            fetchTemplates();
        }
    }, [programLoaded, programDetailsLoaded, programScheduleLoaded, programSchedule, programDetails]);


    // Fetch Client Info
    useEffect(() => {
        const fetchClientInfo = async () => {
            const clientInfo = await fetchClientAccountDetails(programDetails[0].ClientID);
            console.log('Client Info:', clientInfo);
            setClient(clientInfo);
        };

        if (programDetailsLoaded) {
            fetchClientInfo();
        }
    }
        , [programDetailsLoaded, programDetails]);




    useEffect(() => {
        console.log('Program:', program);
        console.log('Details:', programDetails);
        console.log('Schedule:', programSchedule)
        console.log('Templates:', templates);
    }, [programDetails, program]);


    const viewTemplate = template => {
        navigate(`/template/${template.TemplateID}`);
    };

    const handleUpdate = () => {
        navigate('/program-schedule', { state: { programId: programId } });

    };


    const handleOpenModal = async () => {
        const trainerId = localStorage.getItem('userId');
        const fetchedTrainerClients = await fetchClientsProfiles(trainerId);
        console.log('Trainer Clients:', fetchedTrainerClients);
        setTrainerClients(fetchedTrainerClients);
        setIsModalOpen(true);
    }

    const handleDuplicateProgram = async () => {
        const programId = programDetails[0].ProgramID;
        console.log('ProgramDetails:', programDetails);
    
        if (selectedClient) {
            const clientId = Number(selectedClient);
            console.log('Succesfully duplicated program and sending', clientId, programId, 'to duplicateWorkoutProgram');
            await duplicateWorkoutProgram(clientId, programId);
            setIsModalOpen(false);
            navigate("/workout-programs");
        } else {
            console.log('No client selected');
        }
    }



    if (!program || !programDetails || !programSchedule) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <h1>{program.Name}</h1>
            <p>Description: {program.Description}</p>
            <p>Assigned to Client: {client.firstName} {client.UserID}</p>
            {isModalOpen && (
                <div className="modal">
                    <h2>Select client</h2>
                    <select onChange={(e) => setSelectedClient(e.target.value)}>
                        <option value="">Select client</option>
                        {trainerClients.map((client, index) => (
                            <option key={index} value={client.UserID}>
                                {client.FirstName} {client.LastName}
                            </option>
                        ))}
                    </select>
                    <button onClick={handleDuplicateProgram}>Confirm</button>
                    <button onClick={() => setIsModalOpen(false)}>Cancel</button>
                </div>
            )}
            <ul>
                {templates.map((template, index) => (
                    <li key={index}>
                        Day {index + 1}: {template.Name}
                        <button onClick={() => viewTemplate(template)}>View Template</button>
                    </li>
                ))}
            </ul>
            <button onClick={handleUpdate}>Update Program</button>
            <button onClick={handleOpenModal}>Duplicate Program</button>
        </div>
    );

}

export default ProgramTemplateView;