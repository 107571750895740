import React, { useEffect, useState } from 'react';
import { loginUserWithToken, loginUser, importExercises } from '../api/apiHandlers';
import { useNavigate, useLocation } from 'react-router-dom';
import mainLogo from '../assets/mainLogo.png';
import JSONLibrary from './tracking-components/ExerciseData';

const LoginScreen = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isImportingExercises, setIsImportingExercises] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [appVersion, setAppVersion] = useState(null);

    useEffect(() => {
        const storedAppVersion = localStorage.getItem('appVersion');
        setAppVersion(storedAppVersion);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            handleTokenLogin(token);
        }
    }, [location]);

    const handleTokenLogin = async (token) => {
        setLoading(true);
        setError('');
        try {
            const response = await loginUserWithToken(token);
            if (response.success) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('userId', response.userId);
                localStorage.setItem('userType', response.userType);

                await handleExerciseImport(response.userId);

                if (response.userType === 'admin' || response.userType === 'testAdmin') {
                    navigate('/admin-components/AdminHome');
                } else if (response.userType === 'client' || response.userType === 'testClient') {
                    navigate(`/client-workout-sessions/${response.userId}`);
                } else if (response.userType === 'masterUser') {
                    navigate('/master-user-homepage');
                } else {
                    navigate('/clients');
                }
            } else {
                setError('Automatic login failed. Please try logging in manually.');
            }
        } catch (error) {
            console.error('Automatic login failed:', error);
            setError('Automatic login failed. Please try logging in manually.');
        } finally {
            setLoading(false);
        }
    };

    const handleExerciseImport = async (userId) => {
        setIsImportingExercises(false);
        try {
            const importResponse = await importExercises(userId, JSONLibrary);
            if (importResponse.message === "Exercises do not exist, import process can begin.") {
                setIsImportingExercises(true);
                console.log('Exercise import process has begun');
            } else {
                setIsImportingExercises(false);
                console.log(importResponse.message);
            }
        } catch (importError) {
            console.error('Exercise import failed:', importError);
            setIsImportingExercises(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        setIsImportingExercises(false);

        try {
            const response = await loginUser(email, password);
            if (response.success) {
                console.log('Login successful:', response);
                localStorage.setItem('token', response.token);
                localStorage.setItem('userId', response.userId);
                localStorage.setItem('userType', response.userType);

                await handleExerciseImport(response.userId);

                if (response.userType === 'admin' || response.userType === 'testAdmin') {
                    navigate('/admin-components/AdminHome');
                } else if (response.userType === 'client' || response.userType === 'testClient') {
                    navigate(`/client-workout-sessions/${response.userId}`);
                } else if (response.userType === 'masterUser') {
                    navigate('/master-user-homepage');
                } else {
                    navigate('/clients');
                }
            } else {
                setError('Login failed. Please try again.');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setError('Login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleRegisterClick = () => {
        navigate('/register');
    };

    return (
        <div style={styles.loginScreen}>
            <img src={mainLogo} alt="Main Logo" style={{ width: '15rem', marginBottom: '20px' }} />
            {(loading || isImportingExercises) ? (
                <div style={styles.spinner}></div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h2>Login</h2>
                    {error && <div style={styles.errorMessage}>{error}</div>}
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div style={styles.formGroup}>
                        <label style={styles.label}>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={styles.input}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button type="submit" style={styles.button}>Login</button>
                        <button type="button" onClick={handleRegisterClick} style={styles.button}>Register</button>
                    </div>
                    <div>
                        <p style={{ fontSize: '12px', textAlign: 'center' }}>{appVersion}</p>
                    </div>
                </form>
            )}

            {isImportingExercises && (
                <div style={styles.notificationMessage}>
                    Your login time may take a little longer than usual as we are getting your database ready.
                </div>
            )}
        </div>
    );
};

export default LoginScreen;

const styles = {
    loginScreen: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'transparent'
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        display: 'block',
        marginBottom: '5px',
        justifyContent: 'center',
    },
    input: {
        width: '200px',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '10px',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007bff',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    errorMessage: {
        color: 'red',
        marginBottom: '15px',
    },
    spinner: {
        border: '4px solid rgba(0, 0, 0, 0.1)',
        borderTopColor: '#007bff',
        borderRadius: '50%',
        width: '36px',
        height: '36px',
        animation: 'spin 1s linear infinite',
    },
    notificationMessage: {
        color: 'blue',
        marginTop: '15px',
    }
};
