import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { fetchAppointment } from '../../api/apiHandlers';

const AppointmentDetails = () => {
  const { id } = useParams();
  const [appointment, setAppointment] = useState({});

  useEffect(() => {
    const getAppointment = async () => {
      const data = await fetchAppointment(id);
      console.log('Appointment Details:', data);
      setAppointment(data);
    };
    getAppointment();
  }, [id]);

  return (
    <div className="appointment-details">
      <h1>Appointment Details</h1>
      <div className="appointment-info">
        <p><strong>Client:</strong> {appointment.client}</p>
        <p><strong>Date & Time:</strong> {appointment.date}</p>
        <p><strong>Category:</strong> {appointment.category}</p>
        <p><strong>Notes:</strong> {appointment.notes}</p>
      </div>
      <div className="appointment-actions">
        <Link to={`/edit-appointment/${appointment.id}`} className="button">Edit Appointment</Link>
        {/* Add delete button if needed */}
      </div>
    </div>
  );
};

export default AppointmentDetails;
