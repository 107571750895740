import React, { useEffect, useState } from 'react';
import { fetchExercises } from '../../api/apiHandlers';
import CreateExerciseModal from './CreateExerciseModal';
import ExerciseDetailsModal from './ExerciseDetailsModal';
import ExtractedMuscleMap from '../muscle-mapping/ExtractedMuscleMap';
import { Grid } from 'react-virtualized';
import 'react-virtualized/styles.css';

const ExerciseLibrary = () => {
    const [exercises, setExercises] = useState([]);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [trainerId, setTrainerId] = useState(null);
    const [isCreateExerciseModalOpen, setIsCreateExerciseModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [didEditExercise, setDidEditExercise] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredExercises, setFilteredExercises] = useState(exercises);
    const [refreshKey, setRefreshKey] = useState(0);
    const [availableHeight, setAvailableHeight] = useState(600); // Default height
    const [columnCount, setColumnCount] = useState(3); // Default column count
    const [containerWidth, setContainerWidth] = useState(window.innerWidth); // Container width state
    const columnWidth = 350; // Adjust column width as needed
    const gap = 20; // Adjust gap between cards as needed

    // Calculate available height for the list and column count based on container width
    useEffect(() => {
        const updateLayout = () => {
            const createButtonHeight = document.querySelector('.new-exercise-button')?.offsetHeight || 0;
            const newAvailableHeight = window.innerHeight - createButtonHeight - 200; // Adjust this value if needed
            setAvailableHeight(newAvailableHeight);

            const newContainerWidth = document.querySelector('.exercise-container')?.offsetWidth || window.innerWidth;
            setContainerWidth(newContainerWidth);
            const newColumnCount = Math.floor(newContainerWidth / (columnWidth + gap));
            setColumnCount(newColumnCount);
        };

        window.addEventListener('resize', updateLayout);
        updateLayout();

        return () => window.removeEventListener('resize', updateLayout);
    }, []);

    // Fetch Exercises
    useEffect(() => {
        const getExercises = async () => {
            try {
                const trainerId = parseInt(localStorage.getItem('userId'), 10);
                const fetchedExercises = await fetchExercises(trainerId);
                const filteredExercises = fetchedExercises.filter(exercise =>
                    exercise.TrainerUserID === trainerId || exercise.TrainerUserID === 1);
                const sortedExercises = filteredExercises.sort((a, b) => {
                    const nameA = a.Name.toLowerCase();
                    const nameB = b.Name.toLowerCase();
                    return nameA.localeCompare(nameB);
                });
                setExercises(sortedExercises);
            } catch (error) {
                console.error("Failed to fetch exercises:", error);
            }
        };
        getExercises();
    }, [isDetailsModalOpen, didEditExercise]);

    // Filter Exercises
    useEffect(() => {
        const result = exercises.filter(exercise =>
            exercise.Name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredExercises(result);
    }, [exercises, searchTerm]);

    // Toggle Details Modal
    const toggleDetailsModal = (exercise) => {
        setSelectedExercise(exercise);
        setIsDetailsModalOpen(!isDetailsModalOpen);
        console.log('Selected exercise:', exercise);
    };

    // Toggle Create Exercise Modal
    const toggleCreateExerciseModal = () => {
        setIsCreateExerciseModalOpen(!isCreateExerciseModalOpen);
    };

    // Handle Modal Close
    async function handleModalClose() {
        await loadExercises();
    }

    // Load Exercises Again
    const loadExercises = async () => {
        try {
            const trainerUserId = parseInt(localStorage.getItem('userId'), 10);
            const fetchedExercises = await fetchExercises();
            const filteredExercises = fetchedExercises.filter(exercise =>
                exercise.TrainerUserID === trainerUserId || exercise.TrainerUserID === 1);
            const sortedExercises = filteredExercises.sort((a, b) => {
                const nameA = a.Name.toLowerCase();
                const nameB = b.Name.toLowerCase();
                return nameA.localeCompare(nameB);
            });
            setExercises(sortedExercises);
            console.log('Fetched exercises:', sortedExercises);
        } catch (error) {
            console.error("Failed to fetch exercises:", error);
        }
    };

    const rowCount = Math.ceil(filteredExercises.length / columnCount);
    const cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
        const exerciseIndex = rowIndex * columnCount + columnIndex;
        if (exerciseIndex >= filteredExercises.length) {
            return null;
        }
        const exercise = filteredExercises[exerciseIndex];
        return (
            <div
                key={key}
                onClick={() => toggleDetailsModal(exercise)}
                className="exercise-card-item"
                style={{ ...style, margin: `${gap / 2}px` }}
            >
                <div className="exercise-card-content">
                    <div className="exercise-name">
                        {exercise.Name}
                    </div>
                    <div className="muscle-map-render">
                        <ExtractedMuscleMap selectedMuscles={[exercise.PrimaryMuscleGroupName]} />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="exercise-maincontainer">
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                    type="text"
                    placeholder="Search exercise library"
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className="exercise-search-input"
                />
            </div>
            <div className="exercise-container">
                <Grid
                    cellRenderer={cellRenderer}
                    columnCount={columnCount}
                    columnWidth={columnWidth + gap} // Adjust column width to include gap
                    height={availableHeight} // Adjust height as needed
                    rowCount={rowCount}
                    rowHeight={150 + gap} // Adjust row height to include gap
                    width={Math.min(columnCount * (columnWidth + gap), containerWidth)} // Ensure the grid width fits within the container
                    style={{ margin: '0 auto' }} // Center the grid horizontally
                />
            </div>
            <div className="new-exercise-button" style={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '10px',
                background: '#f8f8f8'
            }}>
                <button className="button" onClick={toggleCreateExerciseModal}>Create New Exercise</button>
            </div>
            <CreateExerciseModal
                isCreateExerciseModalOpen={isCreateExerciseModalOpen}
                toggleCreateExerciseModal={toggleCreateExerciseModal}
                onModalClose={handleModalClose}
            />
            <ExerciseDetailsModal
                isDetailsModalOpen={isDetailsModalOpen}
                refreshKey={refreshKey}
                setRefreshKey={setRefreshKey}
                toggleDetailsModal={toggleDetailsModal}
                selectedExercise={selectedExercise}
                onModalClose={handleModalClose}
                availableExercises={exercises}
                onExerciseUpdated={() => { }}
            />
        </div>
    );

};

export default ExerciseLibrary;
