import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { createAppointment, updateAppointment, fetchAppointment, fetchTrainerClients, fetchAppointmentCategories } from '../../api/apiHandlers';
import moment from 'moment-timezone';

const AppointmentForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [client, setClient] = useState('');
  const [date, setDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [category, setCategory] = useState('');
  const [notes, setNotes] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getClients = async () => {
      const trainerId = localStorage.getItem('userId');
      console.log('Trainer ID:', trainerId);
      const data = await fetchTrainerClients(trainerId);
      console.log('Clients Fetched:', data);
      setClients(data);
      setIsLoading(false);
    };

    const getCategories = async () => {
      const data = await fetchAppointmentCategories();
      console.log('Categories Fetched:', data);
      setCategories(data);
    };

    getClients();
    getCategories();
  }, []);

  useEffect(() => {
    if (id) {
      const getAppointment = async () => {
        const data = await fetchAppointment(id);
        setClient(data.client);
        setDate(data.date);
        setEndDate(data.end_date);
        setCategory(data.category_id);
        setNotes(data.notes);
      };
      getAppointment();
    } else if (location.state) {
      const { start, end } = location.state;
      console.log('Received start time from calendar:', start);
      console.log('Received end time from calendar:', end);
      setDate(moment(start).tz('America/Chicago').format('YYYY-MM-DDTHH:mm'));
      setEndDate(moment(end).tz('America/Chicago').format('YYYY-MM-DDTHH:mm'));
    }
  }, [id, location.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trainerId = localStorage.getItem('userId');
    const appointmentData = { 
      client_id: client, 
      trainer_id: trainerId, 
      category_id: category, 
      start_time: date, 
      end_time: endDate, 
      notes 
    };
    console.log('Data sent to server:', appointmentData);
    if (id) {
      await updateAppointment(id, appointmentData);
    } else {
      await createAppointment(appointmentData);
    }
    navigate('/view-appointments');
  };

  return (
    <div className="appointment-form-container">
      <h1>{id ? 'Edit' : 'Create'} Appointment</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="client">Client</label>
          {isLoading ? (
            <p>Loading clients...</p>
          ) : (
            <select id="client" value={client} onChange={(e) => setClient(e.target.value)}>
              <option value="">Select Client</option>
              {clients.map(client => (
                <option key={client.UserID} value={client.UserID}>{client.FirstName} {client.LastName}</option>
              ))}
            </select>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="date">Date & Time</label>
          <input
            type="datetime-local"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="endDate">End Time</label>
          <input
            type="datetime-local"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select id="category" value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.category_id} value={cat.category_id}>{cat.name}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add any notes here"
          />
        </div>
        <button type="submit">{id ? 'Update' : 'Create'} Appointment</button>
      </form>
    </div>
  );
};

export default AppointmentForm;
